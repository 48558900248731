import React from 'react';
import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { BreakTime as BreakIcon, CalendarCompact as AppointmentIcon } from '@linetweet/linetweet-ui';

import { WalkinIcon, BlockerIcon } from 'assets/icons';

import styles from './CalendarFooter.module.scss';

export function CalendarFooter() {
  const intl = useIntl();
  return (
    <Stack className={styles.container}>
      <Stack className={clsx(styles.item, styles.appointment)}>
        <AppointmentIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.appointment' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.reserve)}>
        <WalkinIcon className={styles.icon} width={20} height={20} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.walkin' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.break)}>
        <BreakIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.break' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.blocker)}>
        <BlockerIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.blocker' })}</Typography>
      </Stack>
    </Stack>
  );
}
