import { createAsyncThunk } from '@reduxjs/toolkit';
import CLIENT from '../../utils/CLIENT';

export const getVersion = createAsyncThunk(
  'health/getVersion',
  async (): Promise<{ version: string, date: number } | null> => {
    const response = await CLIENT({
      url: '/health/get-version',
      method: 'GET',
    });

    return response.data;
  },
);
