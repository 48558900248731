import * as yup from 'yup';
import { CalendarEventTypeEnum } from 'types';

export const calendarEventFormSchema = yup.object().shape({
  type: yup
    .string()
    .required()
    .oneOf(Object.values(CalendarEventTypeEnum)),
  date: yup
    .string()
    .required(),
  note: yup
    .string()
    .nullable()
    .defined(),
  slot: yup
    .object()
    .nullable()
    .defined()
    .shape({
      label: yup
        .string()
        .required(),
      value: yup
        .number()
        .required(),
    })
    .when('type', {
      is: (type) => CalendarEventTypeEnum.APPOINTMENT === type,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  gender: yup
    .string()
    .nullable()
    .defined(),
  services: yup
    .array()
    .required()
    .when('type', {
      is: (type) => CalendarEventTypeEnum.APPOINTMENT === type,
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    }),
  employee: yup
    .string()
    .defined()
    .when('type', {
      is: (type) => [CalendarEventTypeEnum.BLOCKER, CalendarEventTypeEnum.BREAK].includes(type),
      then: (schema) => schema.nonNullable(),
      otherwise: (schema) => schema.nullable(),
    }),
  duration: yup
    .number()
    .nullable()
    .defined(),
  title: yup
    .string()
    .nullable()
    .defined()
    .when('type', {
      is: (type) => ([
        CalendarEventTypeEnum.BREAK,
        CalendarEventTypeEnum.BLOCKER,
      ].includes(type)),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  lastName: yup
    .string()
    .required()
    .when('type', {
      is: (type) => ([
        CalendarEventTypeEnum.APPOINTMENT,
        CalendarEventTypeEnum.TASK,
      ].includes(type)),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  firstName: yup
    .string()
    .required()
    .when('type', {
      is: (type) => ([
        CalendarEventTypeEnum.APPOINTMENT,
        CalendarEventTypeEnum.TASK,
      ].includes(type)),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  email: yup
    .string()
    .nullable()
    .email(),
  phoneNumber: yup
    .string()
    .required()
    .matches(/^[0-9]*$/)
    .when('type', {
      is: (type) => ([
        CalendarEventTypeEnum.APPOINTMENT,
        CalendarEventTypeEnum.TASK,
      ].includes(type)),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  recurring: yup
    .string()
    .nullable()
    .defined(),
  from: yup
    .number()
    .nullable()
    .defined()
    .when('type', {
      is: (type) => CalendarEventTypeEnum.APPOINTMENT !== type,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  to: yup
    .number()
    .nullable()
    .defined()
    .when('type', {
      is: (type) => CalendarEventTypeEnum.APPOINTMENT !== type,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
});
