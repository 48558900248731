import React, { useMemo } from 'react';
import { ArrowDown, DialogSection } from '@linetweet/linetweet-ui';
import { MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import styles from 'features/commons/subcomponents/EmployeeSelectField.module.scss';
import { ICalendarEventFormValues } from '../types';

export function AppointmentFormRecurringSection() {
  const form = useFormContext<ICalendarEventFormValues>();
  const intl = useIntl();

  const options = useMemo(() => [{ value: '', label: intl.formatMessage({ id: 'appointment.recurringEventPlaceholder' }) }], []);
  return (
    <DialogSection title={intl.formatMessage({ id: 'appointment.recurringEvent' })}>
      <Controller
        control={form.control}
        name="recurring"
        render={({ field }) => (
          <Select
            fullWidth
            variant="outlined"
            IconComponent={ArrowDown}
            className={styles.container}
            value={field.value || ''}
            displayEmpty
            disabled
            onChange={(event) => {
              field.onChange(event.target.value);
            }}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </DialogSection>
  );
}
