import { createSlice } from '@reduxjs/toolkit';
import { getUser, getUserSettings } from './thunks';
import { redirectToLoginPage } from '../../utils/user';

interface InitialState {
  currentUser: undefined | null | {
    id: string;
    store: string;
  };
  settings?: Record<string, any>;
}

const initialState: InitialState = {
  currentUser: undefined,
  settings: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (state.currentUser) {
        if (state.currentUser.id !== action.payload.id || state.currentUser.store !== action.payload.store) {
          window.location.reload();
        }
      }

      state.currentUser = {
        id: action.payload.id,
        store: action.payload.store,
      };
    });
    builder.addCase(getUser.rejected, () => {
      redirectToLoginPage();
    });
    builder
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
      });
  },
});

export default slice.reducer;
