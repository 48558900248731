/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint react/destructuring-assignment: 0 */
/* eslint react/require-default-props: 0 */

import { Box, Button, Card, CardContent, Chip, Container, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { IMenuLocaleSelectContext } from '@linetweet/linetweet-ui/components/Header/types';
import { Header as TopHeader } from '@linetweet/linetweet-ui';
import { useAppSelector } from 'store/hooks';

import { TimeHelper } from '../../utils';
import { useLanguageOptions } from '../../features/commons';
import { LocaleSelectContext } from '../../layouts/LocaleProvider/LocaleProvider';

import { ReactComponent as GroupMinusIcon } from './icons/GroupMinus.svg';
import { ReactComponent as GroupPlusIcon } from './icons/GroupPlus.svg';
import { ReactComponent as PersonCheckIcon } from './icons/PersonCheck.svg';
import { ReactComponent as StoreIcon } from './icons/Store.svg';
import { ReactComponent as HourGlassIcon } from './icons/HourGlass.svg';
import { ReactComponent as CheckIcon } from './icons/CheckSmall.svg';
import { ReactComponent as CloseSmallIcon } from './icons/CloseSmall.svg';

export function EmployeeExchange() {
  const userSettings = useAppSelector((state) => state.user.settings);

  const data = createData();

  const languageOptions = useLanguageOptions.default();

  const localeContext = useContext(LocaleSelectContext);
  if (!localeContext) {
    throw Error('LocaleSelectContext not configured');
  }
  const urls = userSettings?.urls || {};
  const showButton = true;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#F6F6F8',
        overflow: 'auto',
      }}
    >
      <TopHeader
        title="Demo Store (ha)"
        styleClass="performance-coach"
        clock={{ show: false }}
        profile={{
          show: true,
          userName: 'VT',
          urls: {
            diwa: urls?.operations,
            booking: urls?.appointments,
            performance: urls?.performanceTracker,
          },
        }}
        isAppointmentsAvailable={showButton}
        menu={{
          localeContext: localeContext as IMenuLocaleSelectContext,
          signOut: async () => {},
          languageOptions,
          isOnline: true,
          employeeSettingsLink: {
            show: false,
          },
          settingsLink: {
            show: false,
            source: 'local',
          },
          showOnline: false,
          showLogout: true,
          showContacts: false,
          showLocaleSelector: true,
          translations: {
            menu: 'common.defaultMenuTitle',
            store: 'common.defaultStoreSettings',
          },
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          paddingTop: '22px !important',
          paddingBottom: '22px !important',
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" fontWeight={500}>
            Mitarbeiteraustausch
          </Typography>
          <Typography variant="h6" maxWidth={514} fontWeight={400}>
            Explore our employee lending/borrowing recommendations for seamless staffing solutions.
          </Typography>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ paddingBottom: 10 }}>
        <Stack direction="column" spacing={2}>
          {data.map((dayData, index) => (
            <DateCard key={index} {...dayData} />
          ))}
        </Stack>
      </Container>
    </Box>
  );
}

function DateCard(props: {
  date: Dayjs;
  icon: string;
  status?: string;
  employeesToBorrow: number;
  employeesToLend: number;
  disabled?: boolean;
  stores?: {
    id: string;
    name: string;
    distance: number;
    employeesToLend: number;
    status?: string;
  }[];
}) {
  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid #E0E0E4',
        ...(props.disabled && {
          opacity: 0.5,
          pointerEvents: 'none',
        }),
      }}
    >
      <CardContent>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xs="auto">
            <Box
              sx={{
                backgroundColor: '#F6F6F8',
                border: '1px solid #E0E0E4',
                borderRadius: '4px',
                padding: '10px 19px',

                '& > svg': {
                  height: '38px',
                  width: '38px',
                },
              }}
            >
              {renderTitleIcon(props.icon)}
            </Box>
          </Grid>

          <Grid item xs="auto" display="flex">
            <Stack direction="column" justifyContent="space-between">
              <Typography variant="h5" fontWeight={700} fontSize={20}>
                <strong>{props.date.format('MMM DD, dddd')}</strong>
              </Typography>

              {!!props.employeesToBorrow && (
                <Typography variant="body1" fontWeight={400} fontSize={16}>
                  Employees to borrow: <strong>{props.employeesToBorrow}</strong>
                </Typography>
              )}

              {!!props.employeesToLend && (
                <Typography variant="body1" fontWeight={400} fontSize={16}>
                  Employees to lend: <strong>{props.employeesToLend}</strong>
                </Typography>
              )}

              {!props.employeesToBorrow && !props.employeesToLend && (
                <Typography variant="body1" fontWeight={400} fontSize={16}>
                  No recommendations
                </Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs="auto">
            {props.status && <DayStatusChip status={props.status} />}
          </Grid>

          {!props.disabled && (
            <Grid item xs display="flex" justifyContent="flex-end" alignItems="center">
              <Stack direction="row" spacing={2}>
                {props.status && props.status !== 'denied' && (
                  <ActionButton color="primary" variant="outlined">
                    Deny
                  </ActionButton>
                )}

                {props.status && props.status !== 'approved' && (
                  <ActionButton color="primary" variant="contained">
                    Approve
                  </ActionButton>
                )}
              </Stack>
            </Grid>
          )}

          <Grid item xs={12}>
            {props.stores && (
              <Grid container spacing={2}>
                {props.stores?.map((storeData, index) => (
                  <Grid key={index} item md sm={12} xs={12}>
                    <StoreCard {...storeData} />
                  </Grid>
                ))}
              </Grid>
            )}
            {!props.stores && (
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" fontWeight={400} fontSize={16}>
                  There are no stores for exchange
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Box />
    </Card>
  );
}

function StoreCard(props: { id: string; name: string; distance: number; employeesToLend: number; status?: string }) {
  return (
    <Card
      sx={{
        border: '1px solid #E0E0E4',
        ...(props.status &&
          ['active', 'pending'].includes(props.status) && {
            border: '1px solid #000000',
          }),
        ...(props.status === 'denied' && {
          border: '1px solid #E0E0E4',
        }),
        ...(props.status === 'disabled' && {
          opacity: 0.7,
        }),
      }}
      elevation={0}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Box
              sx={{
                backgroundColor: '#F6F6F8',
                borderRadius: '6px',
                padding: '8px',

                '& > svg': {
                  height: '24px',
                  width: '24px',
                },
              }}
            >
              <StoreIcon />
            </Box>
          </Grid>

          <Grid item xs="auto" display="flex">
            <Stack direction="column" justifyContent="space-between">
              <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                <Typography variant="body1">{props.name}</Typography>

                <Typography variant="body1">{props.distance / 1000} km away</Typography>
              </Stack>

              {!!props.employeesToLend && (
                <Typography variant="body1" fontWeight={400} fontSize={16} lineHeight="15px">
                  Employees to lend: <strong>{props.employeesToLend}</strong>
                </Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs display="flex" alignItems="center" justifyContent="flex-end">
            {props.status && <DayStatusChip status={props.status} size={'small'} />}
          </Grid>
        </Grid>
      </CardContent>
      <Box />
    </Card>
  );
}

const ActionButton = styled(Button)(() => ({
  height: '35px',
  width: '96px',
  fontSize: '16px',
}));

function DayStatusChip(props: { status: string; size?: 'small' | 'default' }) {
  const content = useMemo(() => {
    return (
      {
        approved: {
          text: 'Approved',
          Icon: CheckIcon,
        },
        denied: {
          text: 'Denied',
          Icon: CloseSmallIcon,
        },
        pending: {
          text: 'Pending',
          Icon: HourGlassIcon,
        },
      }[props.status] || null
    );
  }, [props.status]);

  if (!content) return null;

  return (
    <Chip
      label={
        !content ? null : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {props.size !== 'small' && <Typography variant="inherit">{content.text}</Typography>}
            <content.Icon />
          </Stack>
        )
      }
      clickable={false}
      sx={{
        fontSize: '16px',
        height: '24px',
        borderRadius: '4px',
        minWidth: '24px',

        ...(props.status === 'approved' && {
          backgroundColor: '#DEFFF4',
          color: '#01DC94',
        }),
        ...(props.status === 'denied' && {
          backgroundColor: '#FDD6D6',
          color: '#EA3636',
        }),
        ...(props.status === 'pending' && {
          backgroundColor: '#FFF7DE',
          color: '#ECB500',
        }),

        '& > .MuiChip-label': {
          paddingRight: '4px',
          paddingLeft: '8px',

          ...(props.size === 'small' && {
            paddingRight: 0,
            paddingLeft: 0,
          }),

          ...(props.status === 'approved' && {
            color: '#01DC94',
          }),
          ...(props.status === 'denied' && {
            color: '#EA3636',
          }),
        },

        '& svg, path': {
          height: '16px',
          width: '16px',

          ...(props.status === 'approved' && {
            fill: '#01DC94 !important',
          }),
          ...(props.status === 'denied' && {
            fill: '#EA3636 !important',
          }),
        },
      }}
    />
  );
}

const renderTitleIcon = (key: string) => {
  const IconComponent = {
    minus: GroupMinusIcon,
    plus: GroupPlusIcon,
    check: PersonCheckIcon,
  }[key];

  if (!IconComponent) return null;

  return <IconComponent />;
};

const createData = () => {
  let date = TimeHelper.toDayjs(new Date()).startOf('day');
  const createDate = (): Dayjs => {
    date = date.add(1, 'days');

    while ([0].includes(date.day())) {
      date = date.add(1, 'days');
    }

    return date;
  };

  const storesValues = [
    {
      id: '1',
      name: 'Alexa',
      distance: 2 * 1000,
      employeesToLend: 2,
    },
    {
      id: '2',
      name: 'Kiel Zentrum',
      distance: 3 * 1000,
      employeesToLend: 2,
    },
    {
      id: '3',
      name: 'Kiel Wellingdorf',
      distance: 10 * 1000,
      employeesToLend: 2,
    },
  ];

  return [
    // Day 1  always like the first day you did two pending states
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 4,
      employeesToLend: 0,
      stores: [
        {
          ...storesValues[0],
          status: 'pending',
          employeesToLend: 4,
        },
        {
          ...storesValues[1],
          status: 'pending',
          employeesToLend: 4,
        },
        {
          ...storesValues[2],
          status: 'disabled',
          employeesToLend: 4,
        },
      ],
    },
    // Day 2 no recommendation
    {
      date: createDate(),
      icon: 'check',
      employeesToBorrow: 0,
      employeesToLend: 0,
      disabled: true,
      stores: [],
    },
    // Day 3 Denied completely state (the first screenshot I shared)
    {
      date: createDate(),
      status: 'denied',
      icon: 'plus',
      employeesToBorrow: 3,
      employeesToLend: 0,
      stores: [],
    },
    // Day 4 three offers no pending or denied or approved
    {
      status: 'approved',
      date: createDate(),
      icon: 'plus',
      employeesToBorrow: 2,
      employeesToLend: 0,
      // disabled: true,
      stores: [
        { ...storesValues[0], employeesToLend: 2 },
        { ...storesValues[1], employeesToLend: 2 },
        { ...storesValues[2], employeesToLend: 2 },
      ],
    },
    // Day 5 2 offers 1 denied
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 4,
      employeesToLend: 0,
      stores: [
        {
          ...storesValues[0],
          status: 'default',
          employeesToLend: 4,
        },
        {
          ...storesValues[1],
          status: 'denied',
          employeesToLend: 4,
        },
      ],
    },
    // Day 6 no recommendation
    {
      date: createDate(),
      disabled: true,
      icon: 'check',
      employeesToBorrow: 0,
      employeesToLend: 0,
      stores: [],
    },
    // Day 7 Approved completely
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 2,
      employeesToLend: 0,
      // disabled: true,
      stores: [
        {
          ...storesValues[0],
          employeesToLend: 2,
          status: 'approved',
        },
      ],
    },
    // Day 8 No stores to exchange text like in figma
    {
      date: createDate(),
      icon: 'plus',
      employeesToBorrow: 3,
      employeesToLend: 0,
      disabled: true,
      stores: undefined,
    },
    // Day 9 No recommendation
    {
      date: createDate(),
      disabled: true,
      icon: 'check',
      employeesToBorrow: 0,
      employeesToLend: 0,
      stores: [],
    },
    // Day 10 2 offers
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 4,
      employeesToLend: 0,
      stores: [
        {
          ...storesValues[0],
          status: 'default',
          employeesToLend: 4,
        },
        {
          ...storesValues[1],
          status: 'default',
          employeesToLend: 4,
        },
      ],
    },
    // Day  11 1 offer
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 1,
      employeesToLend: 0,
      // disabled: true,
      stores: [
        {
          ...storesValues[0],
          status: 'default',
          employeesToLend: 1,
        },
      ],
    },
    // Day 12 1 offer 1 pending 1 denied
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 2,
      employeesToLend: 0,
      stores: [
        {
          ...storesValues[0],
          status: 'default',
          employeesToLend: 2,
        },
        {
          ...storesValues[1],
          status: 'pending',
          employeesToLend: 2,
        },
        {
          ...storesValues[2],
          status: 'denied',
          employeesToLend: 2,
        },
      ],
    },
    // Day 13 two pending
    {
      date: createDate(),
      status: 'approved',
      icon: 'plus',
      employeesToBorrow: 2,
      employeesToLend: 0,
      stores: [
        {
          ...storesValues[0],
          status: 'pending',
          employeesToLend: 2,
        },
        {
          ...storesValues[1],
          status: 'pending',
          employeesToLend: 2,
        },
      ],
    },
    // Day 14 no recommendation
    {
      date: createDate(),
      disabled: true,
      icon: 'check',
      employeesToBorrow: 0,
      employeesToLend: 0,
      stores: [],
    },
  ];
};
