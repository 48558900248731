import React from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import styles from './DayView.module.scss';

export function DayView(props) {
  const { date, peakTimes, resources, ...restProps } = props;
  const startOfDay = dayjs(date).startOf('day');
  const weekDay = startOfDay.day();
  return (
    <Box className={styles.dayContainer}>
      <TimeGrid
        {...restProps}
        range={[date]}
        resources={resources.map((resource) => ({
          ...resource,
          weekDay,
        }))}
      />
    </Box>
  );
}

DayView.navigate = (date) => date;
DayView.title = () => '';
