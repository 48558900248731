import React, { useMemo } from 'react';
import { alpha, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { CalendarCompact } from '@linetweet/linetweet-ui';

import { getServiceIcon } from 'utils';
import { CustomEventProps } from '../types';

import styles from './DayEventColored.module.scss';

export function DayEventColored(
  {
    event,
  }: CustomEventProps,
) {
  const intl = useIntl();

  const eventIconColor = useMemo(() => (
    event.services?.[0]?.color?.main
  ), [event.services]);

  const startTime = event.startFormatted;

  const endTime = event.endFormatted;

  const height = useMemo(() => {
    if (!event.duration) {
      return 10;
    }
    for (let i = 10; i < 50; i += 5) {
      if (event.duration <= i) {
        return i;
      }
    }
    return 50;
  }, [event.duration]);

  const title = useMemo(() => (
    event.services
      .map((service) => (intl.formatMessage({ id: service.name })))
      .join(' + ')
  ), [event.services]);

  return (
    <div
      className={clsx(styles.root, {
        [styles.height45orMore]: height >= 45,
        [styles.height5to15]: height < 15,
        [styles.height30]: height === 30,
      })}
    >
      { height >= 15 && (
        <>
          <Box
            className={clsx(styles.iconRoot, {
              [styles.height45orMore]: height >= 45,
              [styles.height5to15]: height < 20,
            })}
            sx={{ '& path': { fill: eventIconColor } }}
          >
            <CalendarCompact />
          </Box>

          <div
            className={clsx(styles.header, {
              [styles.is30orMore]: height >= 30,
            })}
          >
            { height >= 30 && (
              <p className={styles.title}>
                <strong>
                  { title }
                </strong>
              </p>
            ) }

            <p className={styles.time}>
              {startTime} - {endTime}
            </p>
          </div>
        </>
      )}

      {!!event.services.length && (
        <div
          className={styles.services}
        >
          {event.services.map((service) => (
            <div
              key={service.id}
              className={clsx(styles.serviceWrapper, {
                [styles.height15to25]: height < 25 && height >= 15,
                [styles.height5to15]: height < 15,
              })}
              style={{ backgroundColor: alpha(eventIconColor || '#858585', 0.15) }}
            >
              {getServiceIcon(service.icon)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
