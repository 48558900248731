import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';

type SpinProps = {
  loading?: boolean;
};

export function Spin(
  {
    children,
    loading,
  }: PropsWithChildren<SpinProps>,
) {
  return (
    <>
      <Box
        className={styles.wrapper}
        onClick={(event) => event.stopPropagation()}
      >
        {loading && (
          <Box className={styles.loading_container} />
        )}
        {children}
      </Box>
    </>
  );
}

Spin.defaultProps = {
  loading: undefined,
};

export default Spin;
