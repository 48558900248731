import { createSlice } from '@reduxjs/toolkit';
import { getEventStats } from './thunks';
import { DaySummary } from '../../types';

interface InitialState {
  loading: boolean;
  error: any;
  data: Record<string, DaySummary>;
  queryHaveResponse: Record<string, boolean>;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: { },
  queryHaveResponse: {},
};

const slice = createSlice({
  name: 'eventStats',
  initialState,
  reducers: {
    updateEventStats: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventStats.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.queryHaveResponse[`${action.meta.arg.from.valueOf()}-${action.meta.arg.to.valueOf()}`] = false;
    });
    builder.addCase(getEventStats.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = { ...state.data, ...action.payload };
      state.queryHaveResponse[`${action.meta.arg.from.valueOf()}-${action.meta.arg.to.valueOf()}`] = true;
    });
    builder.addCase(getEventStats.rejected, (state, action) => {
      state.loading = false;
      state.queryHaveResponse[`${action.meta.arg.from.valueOf()}-${action.meta.arg.to.valueOf()}`] = false;
      state.error = action.error;
    });
  },
});

export const { updateEventStats } = slice.actions;
export default slice.reducer;
