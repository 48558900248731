import React from 'react';
import { useIntl } from 'react-intl';

import { ResyncDialog } from './ResyncDialog';

type BreaksResyncDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
};

export const BreaksResyncProps: React.FC<BreaksResyncDialogProps> = (props) => {
  const intl = useIntl();

  return (
    <ResyncDialog
      {...props}
      title={intl.formatMessage({ id: 'menu.confirmBreaksResyncTitle' })}
      content={intl.formatMessage({ id: 'menu.confirmBreaksResyncContent' })}
    />
  );
};
