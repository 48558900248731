import React, { ReactElement, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as icons from '@linetweet/linetweet-ui';

import { Service } from '../types';

export const getServiceIcon = (icon?: string): ReactNode => {
  if (!icon) {
    return null;
  }
  const IconComponent = icons[icon];
  if (!IconComponent) {
    return null;
  }
  return React.cloneElement(IconComponent() as ReactElement, { key: `service-icon-svg-${uuidv4()}` });
};

export const getServicesIconsByIds = (services: Service[] = []): ReactNode[] => services.map((child) => getServiceIcon(child.icon));

export const mapServiceIdsToServices = (allServices: Service[], serviceIds: string[]): Service[] => {
  if (!allServices) {
    return [];
  }
  if (!Array.isArray(serviceIds)) {
    return [];
  }
  return serviceIds
    .map((serviceId: string) => allServices.find((service) => service.id === serviceId))
    .filter((service) => !!service) as Service[];
};
