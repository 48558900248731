import React, { useMemo, useState } from 'react';
import { ClickableField, DialogSection, IOption, Nullable } from '@linetweet/linetweet-ui';
import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { Spin } from 'features/commons';
import styles from './SlotSelectSection.module.scss';
import { SlotButtonGroup } from '../subcomponents';

type Props = {
  value: Nullable<IOption>;
  onChange: (value: Nullable<IOption>) => void;
  options: IOption[];
  loading?: boolean;
  isWarning?: boolean;
  isDisabled?: boolean;
  before?: React.ReactNode;
};

export function SlotSelectSection({ before, value, isWarning, onChange, options: propsOptions, loading, isDisabled }: Props) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const options = useMemo(() => {
    if (value?.value) {
      const filteredOptions = propsOptions.filter((option) => (
        option.value !== value?.value
      ));
      return [value, ...filteredOptions];
    }

    return propsOptions;
  }, [propsOptions, value]);

  const preferredOptions = useMemo(() => options.slice(0, 3), [options]);

  return (
    <Spin loading={loading}>
      <DialogSection
        title={intl.formatMessage({ id: 'appointment.availableTimes' })}
        className={isWarning ? styles.warningContainer : undefined}
      >
        <Stack direction={isExpanded ? 'column' : 'row'} alignItems="baseline">
          <SlotButtonGroup
            value={value}
            isDisabled={!!isDisabled}
            onChange={onChange}
            options={isExpanded ? options : preferredOptions}
            before={before}
          />

          {options.length > 3 ? (
            <ClickableField
              disabled={loading || !!isDisabled}
              onClick={() => setIsExpanded(!isExpanded)}
              label={intl.formatMessage({ id: isExpanded ? 'appointment.showPreferred' : 'appointment.showAll' })}
            />
          ) : (
            ''
          )}
        </Stack>
      </DialogSection>
    </Spin>
  );
}

SlotSelectSection.defaultProps = {
  before: undefined,
  loading: undefined,
  isWarning: false,
  isDisabled: undefined,
};
