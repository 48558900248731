import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { BreakTime as BreakIcon, CalendarCompact as AppointmentIcon } from '@linetweet/linetweet-ui';

import { BlockerIcon, PinnedIcon, ReserveIcon, TaskIcon, WalkinIcon } from 'assets/icons';
import { getServiceIcon } from 'utils';
import { CalendarEventTypeEnum, CalendarEventStatusENUM } from 'types';

import { CustomEventProps } from '../types';
import styles from './DayEventBase.module.scss';

export function DayEventBase({ event, title }: CustomEventProps) {
  const { duration, startFormatted, endFormatted, services, note, suggestedEmployee } = event;
  const intl = useIntl();
  const startTime = startFormatted;
  const endTime = endFormatted;
  const minutes = duration || 0;

  const isBreak = useMemo(() => event.type === CalendarEventTypeEnum.BREAK, [event]);
  const isBlocker = useMemo(() => event.type === CalendarEventTypeEnum.BLOCKER, [event]);
  const isTask = useMemo(() => event.type === CalendarEventTypeEnum.TASK, [event]);
  const isAppointment = useMemo(() => event.type === CalendarEventTypeEnum.APPOINTMENT, [event]);
  const isWalkin = useMemo(() => event.type === CalendarEventTypeEnum.WALKIN, [event]);
  const isReserve = useMemo(() => event.type === CalendarEventTypeEnum.RESERVATION, [event]);
  const showSlot = useMemo(() => event.type === CalendarEventTypeEnum.SLOT, [event]);
  const showWorkingHours = useMemo(() => event.type === CalendarEventTypeEnum.WORKING_HOURS, [event]);
  const hasNotes = useMemo(() => !!note, [event]);

  const height = useMemo(() => {
    for (let i = 10; i < 40; i += 5) {
      if (minutes <= i) {
        return i;
      }
    }
    return 40;
  }, [minutes]);

  const showTitle = useMemo(
    () => title && !showWorkingHours && !isWalkin && minutes >= 30,
    [minutes, isWalkin, showWorkingHours, title],
  );

  const showWalkinTitle = useMemo(
    () => isWalkin && !showWorkingHours && minutes >= 30,
    [minutes, isWalkin, showWorkingHours],
  );

  const showReserveTitle = useMemo(
    () => isReserve && !showWorkingHours && minutes >= 30,
    [minutes, isReserve, showWorkingHours],
  );

  const showTime = useMemo(() => {
    if (showSlot) {
      return true;
    }
    if (showWorkingHours) {
      return false;
    }
    return true;
  }, [minutes, isBreak, showSlot, showWorkingHours]);

  const showServices = useMemo(() => {
    if (showSlot && minutes >= 45) {
      return true;
    }
    if (!showWorkingHours && !isBreak) {
      return true;
    }
    return false;
  }, [minutes, isBreak, showSlot, showWorkingHours]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.height10]: height === 10,
        [styles.height15]: height === 15,
        [styles.height20]: height === 20,
        [styles.height25]: height === 25,
        [styles.height30]: height === 30,
        [styles.height35]: height === 35,
        [styles.slotWrapper]: showSlot,
        [styles.walkinWrapper]: isWalkin,
        [styles.reserveWrapper]: isReserve,
        [styles.appointment]: isAppointment,
        [styles.completed]: event.status === CalendarEventStatusENUM.COMPLETED,
      })}
    >
      <div className={styles.columns}>
        <div className={styles.iconContainer}>
          {isBreak && (<BreakIcon className={styles.breakIcon} />)}
          {isWalkin && (<WalkinIcon className={styles.walkinIcon} />)}
          {isBlocker && (<BlockerIcon className={styles.blockerIcon} />)}
          {isTask && (<TaskIcon className={styles.taskIcon} />)}
          {isReserve && (<ReserveIcon className={styles.reserveIcon} />)}
          {isAppointment && (<AppointmentIcon className={styles.appointmentIcon} />)}
        </div>

        <div
          className={styles.textAndNoteContainer}
        >
          <div className={styles.textContainer}>
            {showReserveTitle && (
              <p className={styles.reserveTitle}>
                {intl.formatMessage({ id: 'calendar.reserveTitle' })}
              </p>
            )}
            {showTitle && (
              <p className={clsx(styles.title, { [styles.compactTitle]: !!suggestedEmployee })}>
                {title}
              </p>
            )}
            {showWalkinTitle && (
              <p className={styles.walkinTitle}>
                {intl.formatMessage({ id: 'calendar.walkinTitle' })}
              </p>
            )}
            {showTime && (
              <p className={styles.startTime}>
                {startTime} - {endTime}
              </p>
            )}
          </div>

          {minutes >= 45 && hasNotes && (
          <p className={styles.note}>
            {note}
          </p>
          )}
        </div>

        <div
          className={clsx(styles.servicesContainer, { [styles.columnedServicesContainer]: minutes >= 45 })}
        >
          {showServices && !!services.length && (
            <div className={styles.services}>
              {services.map((service) => (
                <div
                  key={service.id}
                  className={styles.serviceWrapper}
                >
                  {getServiceIcon(service.icon)}
                </div>
              ))}
            </div>
          )}
          {suggestedEmployee && (
            <PinnedIcon className={clsx(styles.pinnedIcon, { [styles.offsetPin]: (isBreak || isBlocker) && minutes > 30 })} />
          )}
        </div>
      </div>
    </div>
  );
}
