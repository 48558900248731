import { io } from 'socket.io-client';

export const socketIo = io(`${process.env.REACT_APP_API_BASE_URL}/lt-booking`, {
  path: `${process.env.REACT_APP_API_PATH}/socket.io/`,
  withCredentials: true,
  autoConnect: false,
});

export async function requestData<T>(eventName: string, data?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    socketIo.timeout(15000).emit(eventName, data, (error, data) => {
      if (error) {
        console.log(`socket.requestData Eror: ${error}`);
        reject(error);
        return;
      }

      if (data && data?.error) {
        console.log(`socket.requestData data.error: ${data?.error}`);
        reject(new Error(`Cannot get data from the server: ${eventName}, error: ${data.error}`));
        return;
      }

      resolve(data as T);
    });
  });
}

export function mapBulkPayload<T extends { id: string }>(data: T[], payload: { create: T[]; update: T[]; delete: T[] }): T[] {
  const itemsById: {
    create: { [id: string]: T };
    update: { [id: string]: T };
    delete: { [id: string]: T };
  } = Object.entries(payload).reduce(
    (acc, [operation, items]) => {
      items.forEach((item) => {
        acc[operation][item.id] = item;
      });
      return acc;
    },
    { create: {}, update: {}, delete: {} },
  );
  const updatedData = data.map((item) => {
    if (itemsById.update[item.id]) {
      return { ...item, ...itemsById.update[item.id] };
    }
    if (itemsById.delete[item.id]) {
      return undefined;
    }
    return item;
  });
  return (updatedData.filter((event) => !!event) as T[]).concat(payload.create);
}
