import React from 'react';
import clsx from 'clsx';
import { Box, Stack } from '@mui/material';
import { PeakCustomers as PeakTimeIcon } from '@linetweet/linetweet-ui';

import styles from './PeakTime.module.scss';

export function PeakTime({ isPeakHour }) {
  return (
    <Box
      className={clsx(styles.peakTime, {
        [styles.isPeakHour]: isPeakHour,
      })}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" className={styles.iconsContainer}>
        {isPeakHour && (
          <>
            <PeakTimeIcon />
            <PeakTimeIcon />
            <PeakTimeIcon />
          </>
        )}
      </Stack>
    </Box>
  );
}
