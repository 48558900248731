import { createSlice } from '@reduxjs/toolkit';

import { resyncEpep } from './thunks';

interface InitialState {
  loading: boolean;
  error: any;
  data: any;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: [],
};

const slice = createSlice({
  name: 'epep',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resyncEpep.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(resyncEpep.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });
    builder.addCase(resyncEpep.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error; // todo check it
    });
  },
});

export default slice.reducer;
