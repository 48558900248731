export const parseEmployeeNameWithEmoji = (displayName?: string): { name: string; fullName: string } => {
  let name = '';
  let fullName = '';
  const emojiRegex = /\p{Emoji}/gu;

  if (!displayName) {
    return { name, fullName };
  }

  const trimmed = displayName.trim();
  const indexes = trimmed.match(emojiRegex);
  let trimmedEmojis = trimmed;
  if (indexes) {
    for (let emoji of indexes) {
      if (trimmedEmojis.indexOf(emoji) > -1) {
        trimmedEmojis = trimmedEmojis.replaceAll(emoji, '');
      }
    }
  }
  const words = trimmedEmojis.split(' ').filter((word) => !!word && !indexes?.includes(word));
  // default values
  name = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words?.[0]?.[0] || '';
  fullName = trimmed;

  if (!indexes || indexes.length < 0) {
    // have no emoji in name, show initials
    return { name, fullName };
  }

  const firstEmoji = indexes[0];
  if (trimmed.indexOf(firstEmoji) === 0) {
    // name starts with emoji, cut it
    name = firstEmoji;
    fullName = trimmed.replace(firstEmoji, '').trim();
    return { name, fullName };
  }

  const lastEmoji = indexes[indexes.length - 1];
  if (trimmed.lastIndexOf(lastEmoji) + lastEmoji.length === trimmed.length) {
    // name ends with emoji, cut it
    name = lastEmoji;
    fullName = trimmed.substring(0, trimmed.lastIndexOf(lastEmoji)).trim();
    return { name, fullName };
  }

  // emoji not in the start and not in the end, show as it is
  return { name, fullName };
};
