import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { Nullable } from '@linetweet/linetweet-ui';

export const selectAvailableDates = createSelector(
  [
    (state: RootState) => state.slots.slots,
    (state: RootState, serviceIds: string[], employeeId: Nullable<string>) => ({ serviceIds, employeeId }),
  ],
  (slotsState, { serviceIds, employeeId }): string[] => {
    const slotsData = slotsState.data[serviceIds.join(',')];

    if (!slotsData) {
      return [];
    }

    return Object.entries(slotsData).reduce<string[]>((acc, [date, employeeSlotsData]) => {
      if (employeeSlotsData) {
        Object.entries(employeeSlotsData).forEach(([slotsEmployeeId, employeeSlots]) => {
          if (employeeId && slotsEmployeeId !== employeeId) {
            return;
          }

          if (!employeeSlots.length) {
            return;
          }

          if (acc.includes(date)) {
            return;
          }

          acc.push(date);
        });
      }
      return acc;
    }, []);
  },
);
