import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { DialogSection, Nullable, TimePickerDialog } from '@linetweet/linetweet-ui';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { TimeHelper } from 'utils';
import { ICalendarEventFormValues } from '../types';

import styles from './AppointmentFormTimeSection.module.scss';

type Props = {
  isDisabled?: boolean;
};

export function AppointmentFormTimeSection({ isDisabled }: Props) {
  const intl = useIntl();

  const form = useFormContext<ICalendarEventFormValues>();
  const [isTimePickerDialogOpen, setIsTimePickerDialogOpen] = useState<boolean>(false);
  const [timePickerValue, setTimePickerValue] = useState<Nullable<number>>(null);
  const [timePickerField, setTimePickerField] = useState<'from' | 'to'>('from');

  const onTimePickerDialogOpen = useCallback((fieldName: 'from' | 'to', value: Nullable<number>) => {
    setTimePickerValue(value || null);
    setTimePickerField(fieldName);
    setIsTimePickerDialogOpen(true);
  }, []);

  const onTimePickerDialogSubmit = useCallback((fieldName: string, newTime: number) => {
    setIsTimePickerDialogOpen(false);
    form.setValue(fieldName as 'from' | 'to', newTime, { shouldValidate: true, shouldDirty: true });
    setTimePickerValue(null);
  }, []);

  const onTimePickerDialogClose = useCallback(() => {
    setIsTimePickerDialogOpen(false);
    setTimePickerValue(null);
  }, []);

  const fromValue = form.watch('from');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DialogSection title={intl.formatMessage({ id: 'common.from' })}>
            <Controller
              control={form.control}
              name="from"
              render={({ field, fieldState }) => (
                <DesktopTimePicker
                  value={field.value != null ? dayjs(new Date()).startOf('day').add(field.value, 'minutes') : null}
                  disabled={!!isDisabled}
                  onChange={(value) => {
                    if (value == null) {
                      field.onChange(value);
                      return;
                    }

                    field.onChange(TimeHelper.getMinutesFromDayStart(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      disabled={!!isDisabled}
                      className={styles.timeInput}
                      onClick={() => {
                        onTimePickerDialogOpen(field.name, field.value);
                      }}
                      onBlur={field.onBlur}
                      variant="outlined"
                      required
                      error={!!fieldState.error}
                    />
                  )}
                />
              )}
            />
          </DialogSection>
        </Grid>

        <Grid item xs={6}>
          <DialogSection title={intl.formatMessage({ id: 'common.to' })}>
            <Controller
              control={form.control}
              name="to"
              render={({ field, fieldState }) => (
                <DesktopTimePicker
                  minTime={fromValue != null ? dayjs(new Date()).startOf('day').add(8, 'hours') : null}
                  value={field.value != null ? dayjs(new Date()).startOf('day').add(field.value, 'minutes') : null}
                  disabled={!!isDisabled}
                  onChange={(value) => {
                    if (value == null) {
                      field.onChange(value);
                      return;
                    }

                    field.onChange(TimeHelper.getMinutesFromDayStart(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      disabled={!!isDisabled}
                      onClick={() => {
                        onTimePickerDialogOpen(field.name, field.value);
                      }}
                      onBlur={field.onBlur}
                      className={styles.timeInput}
                      required
                      error={!!fieldState.error}
                    />
                  )}
                />
              )}
            />
          </DialogSection>
        </Grid>
      </Grid>
      <TimePickerDialog
        open={isTimePickerDialogOpen}
        initialValue={timePickerValue}
        fieldName={timePickerField}
        onSubmit={onTimePickerDialogSubmit}
        onClose={onTimePickerDialogClose}
      />
    </>
  );
}

AppointmentFormTimeSection.defaultProps = {
  isDisabled: undefined,
};
