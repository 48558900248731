import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export function LoginBox({ loginUrl }: { loginUrl: string }) {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: '40%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          type="link"
          href={loginUrl}
          fullWidth
          variant="contained"
        >
          Login
        </Button>
      </Box>
    </Container>
  );
}
