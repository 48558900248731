import React, { useEffect, useMemo, useState } from 'react';
import { ClickableField, DialogSection, IOption, Nullable } from '@linetweet/linetweet-ui';
import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { Spin } from 'features/commons';
import { SlotButtonGroup } from '../subcomponents';

type Props = {
  value: Nullable<IOption>;
  onChange: (value: Nullable<IOption>) => void;
  options: IOption[];
  loading?: boolean;
};

export function PreferredSlotSelectSection({ value, onChange, options: propsOptions, loading }: Props) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const options = useMemo(() => {
    const hasValueInTheList: boolean = !!propsOptions.find((option) => option.value === value?.value);

    if (value?.value && !hasValueInTheList) {
      return [value, ...propsOptions];
    }

    return propsOptions;
  }, [propsOptions, value]);

  const preferredOptions = useMemo(() => options.slice(0, 3), [options]);

  useEffect(() => {
    if (!isExpanded && value?.value && !preferredOptions.find((option) => option.value === value.value)) {
      setIsExpanded(true);
    }
  }, [preferredOptions, value]);

  return (
    <Spin loading={loading}>
      <Stack direction="column" spacing={2.5}>
        <DialogSection title={intl.formatMessage({ id: 'appointment.preferredTimes' })}>
          <SlotButtonGroup value={value} onChange={onChange} options={preferredOptions} />
        </DialogSection>

        {isExpanded && (
          <DialogSection title={intl.formatMessage({ id: 'appointment.availableTimes' })}>
            <SlotButtonGroup value={value} onChange={onChange} options={options} />
          </DialogSection>
        )}
        {options.length > 3 ? (
          <ClickableField
            disabled={loading}
            onClick={() => setIsExpanded(!isExpanded)}
            label={intl.formatMessage({ id: isExpanded ? 'appointment.showPreferred' : 'appointment.showAll' })}
          />
        ) : (
          ''
        )}
      </Stack>
    </Spin>
  );
}

PreferredSlotSelectSection.defaultProps = {
  loading: undefined,
};
