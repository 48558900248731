import React from 'react';
import { Box, Stack, Skeleton } from '@mui/material';
import clsx from 'clsx';

import { PeakTime } from '../subcomponents';

import styles from './TimeSlotWrapper.module.scss';

export function TimeSlotWrapper(...args: any[]) {
  const [peakTimes, loading, props] = args;
  const { children } = props;
  let peakTime;
  if (peakTimes && children.props.className === 'rbc-time-slot') {
    const timeString = children.props.children?.props.children;
    peakTime = peakTimes.find((hours) => hours.start === timeString);
  }

  if (loading) {
    let skeletonComponent = (
      <Stack alignItems="center">
        <Skeleton animation="wave" variant="rounded" width="98%" height={50} />
      </Stack>
    );
    // in case of "time" slot wrapper
    if (!props.resource) {
      skeletonComponent = (
        <Stack alignItems="center">
          <Skeleton animation="wave" width="75%" height={25} />
        </Stack>
      );
    }

    return <Box className={clsx('rbc-time-slot', styles.timeSlotContainer)}>{skeletonComponent}</Box>;
  }
  return (
    <Box className={clsx('rbc-time-slot', styles.timeSlotContainer)}>
      {loading}
      {children}
      {peakTime && <PeakTime isPeakHour={peakTime.isPeakHour} />}
    </Box>
  );
}
