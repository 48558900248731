import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Box, IconButton, Stack, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar as ReactCalendar, CalendarProps } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Filter as FilterIcon, LoadingWrapper, NewSearch as SearchIcon, Notice, NoticeStack } from '@linetweet/linetweet-ui';

import { DayjsLocalizer, isArrayEqual, TimeHelper } from 'utils';
import { CalendarEventTypeEnum, Employee, Shift, Service } from 'types';

import { LocaleSelectContext } from 'layouts/LocaleProvider/LocaleProvider';

import { setTags } from 'utils/sentry';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getCalendarSlots } from '../../store/calendarSlots/thunks';
import { fetchCalendarEvents } from '../../store/appointments/thunks';
import { fetchEmployees, getEmployeesByIds } from '../../store/employees/thunks';
import { listenDateChanges } from '../../store/global/thunks';
import { ConnectionType } from '../../store/global/slice';
import { fetchPeakTimes } from '../../store/peakTimes/thunks';
import { getEventStats } from '../../store/eventStats/thunks';
import { selectStoreServices } from '../../store/store/selectors';

import { buildAppointmentEvents } from '../commons/services/appointmentsCalendarService';
import { convertDaySummaryToCalendarDataType } from '../commons/services/calendarOverviewService';

import { AppointmentFilters, CalendarEvent } from './types';
import {
  AppointmentsFilters,
  CreateAppointmentButton,
  DatePicker,
  DayEvent,
  DayOverview,
  DayView,
  ResourceHeader,
  RoomsAmount,
  RoomsAmountValues,
  TimeSlotWrapper,
} from './components';
import { CalendarFooter, EventContainerWrapper, EventWrapper, TimeGutterHeader } from './subcomponents';

import { getMissingShiftEmployeeIds } from '../../store/employees/selectors';

import { getDayViewTimeRange } from './utils';

import styles from './AppointmentsCalendar.module.scss';

export type AppointmentsCalendarContainerProps = {
  onAppointmentClick: (params: { calendarEvent: CalendarEvent, isPastEvent: boolean }) => void;
  onCreateAppointmentClick: (params?: { services: Service[]; time: number; date: number }) => void;
  date: Dayjs;
  onDateChange: (date: Dayjs) => void;
  onShowCalendarOverviewClick: () => void;
};

const defaultFilters: AppointmentFilters = {
  focus: [],
  employee: null,
  workingHours: {
    from: 8 * 60,
    to: 20 * 60,
  },
  services: [],
};

export function AppointmentsCalendar({
  onAppointmentClick,
  onCreateAppointmentClick,
  date,
  onDateChange,
  onShowCalendarOverviewClick,
}: AppointmentsCalendarContainerProps) {
  const intl = useIntl();

  const appointmentsState = useAppSelector((state) => state.appointments);
  const employeesState = useAppSelector((state) => state.employees);
  const storeState = useAppSelector((state) => state.store);
  const peakTimesState = useAppSelector((state) => state.peakTimes);
  const calendarSlotsState = useAppSelector((state) => state.calendarSlots);
  const globalState = useAppSelector((state) => state.global);
  const eventStatsState = useAppSelector((state) => state.eventStats);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [view, setView] = useState<'day' | 'week'>('day');
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [offlineEvents, setOfflineEvents] = useState<CalendarEvent[]>([]);
  const [workingHours, setWorkingHours] = useState({ isLoading: true }) as any;
  const [timeZone, setTimezone] = useState('Europe/Berlin');
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const [filters, setFilters] = useState<AppointmentFilters>(defaultFilters);
  const [deletionWarningState, setDeletionWarningState] = useState<'visible' | 'disabled' | 'hidden'>('hidden');
  const [deletionErrorState, setDeletionErrorState] = useState<boolean>(false);
  const [slotsErrorShown, setSlotsErrorShown] = useState(false);
  const [calendarData, setCalendarData] = useState<any>({ events: [], resources: [], eventTypeCounters: {}, loading: true });
  const [timer, setTimer] = useState(TimeHelper.convertToMinutesFromDayStart(TimeHelper.toDayjs(Date.now())));

  const localeContext = useContext(LocaleSelectContext);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dateString = useMemo(() => TimeHelper.toStandardFormat(date), [date]);

  const missingEmployeeIds = useAppSelector((state) => getMissingShiftEmployeeIds(state, dateString));

  const isFiltersActive = useMemo(() => !isArrayEqual(filters, defaultFilters), [filters]);

  const notLoading = useMemo(
    () => !appointmentsState.loading && !employeesState.loading && !storeState.loading && !calendarSlotsState.loading,
    [appointmentsState.loading, employeesState.loading, storeState.loading, calendarSlotsState.loading],
  );

  useEffect(() => {
    if (globalState.connectionStatus === ConnectionType.Connected) {
      dispatch(listenDateChanges(dateString));
    }
  }, [dateString, globalState.connectionStatus]);

  useEffect(() => {
    if (date.valueOf() !== TimeHelper.toDayjs(Date.now()).startOf('day').valueOf()) {
      return;
    }
    const intervalId = setInterval(() => {
      const updatedValue = timer + 1;
      setTimer(updatedValue);
    }, 60000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [timer, dateString]);

  useEffect(() => {
    if (appointmentsState.deletionError) {
      setDeletionErrorState(true);
      setDeletionWarningState('hidden');
    } else {
      setDeletionErrorState(false);
      if (appointmentsState.deleting) {
        if (deletionWarningState === 'hidden') {
          setDeletionWarningState('visible');
        }
      } else {
        setDeletionWarningState('hidden');
      }
    }
  }, [appointmentsState, deletionWarningState]);

  useEffect(() => {
    if (!storeState.data || !storeState.data.id) {
      return;
    }
    if (storeState.data.hours) {
      let storeHours = storeState.data.hours.specialDates.find((workingDate) => workingDate.date === dateString);
      if (!storeHours) {
        const selectedWeekDay = date.day();
        storeHours = storeState.data.hours.regular.find((workingDay) => workingDay.weekday === selectedWeekDay);
      }
      if (workingHours !== storeHours) {
        setWorkingHours(storeHours);
      }
    }
    if (storeState.data.timezone && storeState.data.timezone !== timeZone) {
      setTimezone(storeState.data.timezone);
      onDateChange(TimeHelper.toDayjs(Date.now(), timeZone).startOf('day'));
    }
    setTags({ storeId: storeState.data.id });

    if (!eventStatsState.data || !eventStatsState.data[dateString]) {
      if (eventStatsState.loading) {
        return;
      }
      dispatch(
        getEventStats({
          from: date.startOf('day'),
          to: date.endOf('day'),
          storeId: storeState.data.id,
          enterpriseId: storeState.data.enterpriseId,
        }),
      );
    }
  }, [storeState.data?.id, dateString]);

  useEffect(() => {
    if (!storeState.data?.id || !storeState.data?.enterpriseId) {
      return;
    }
    const from = view === 'day' ? date.startOf('day') : date.startOf('week');
    const to = from.add(1, view);
    const storeId = storeState.data?.id;
    const enterpriseId = storeState.data?.enterpriseId;

    dispatch(getCalendarSlots({ date: dateString, timestamp: Date.now() }));
    dispatch(fetchCalendarEvents({ from, to, timeZone }));
    dispatch(fetchEmployees({ from, ...(view === 'week' && { to }) }));
    dispatch(fetchPeakTimes({ date: from, timeZone, storeId, enterpriseId }));
  }, [storeState.data?.id, dateString, view]);

  useEffect(() => {
    setSlotsErrorShown(Boolean(calendarSlotsState.error));
  }, [calendarSlotsState.error]);

  const employeeNameById = useMemo(() => {
    if (employeesState.employees.length < 1) {
      return {};
    }
    return employeesState.employees.reduce((acc, employee: Employee) => {
      acc[employee.id] = `${employee.firstName} ${employee.lastName}`;
      return acc;
    }, {});
  }, [employeesState.employees]);

  useEffect(() => {
    if (employeesState.loading || employeesState.error || !workingHours.isOpen) {
      return;
    }
    const dateStr = TimeHelper.toStandardFormat(date);
    if (!employeesState.shifts[dateStr] || employeesState.shifts[dateStr].length < 1) {
      return;
    }
    if (isArrayEqual(employeesState.shifts[dateStr], shifts) && !filters.employee && filters.services.length < 1 && !filters.focus) {
      return;
    }
    const employeeIdsByFocusMap: Record<string, string[]> = appointmentsState.data.reduce((acc, appointment) => {
      if (appointment.type === CalendarEventTypeEnum.APPOINTMENT) {
        return acc;
      }

      if (!appointment.type) {
        return acc;
      }
      if (appointment.date !== dateStr) {
        return acc;
      }
      // @ts-ignore
      let employeeId = appointment.pinnedEmployee;
      // @ts-ignore
      if (appointment.eventTypeData && appointment.eventTypeData.suggestedEmployee) {
        // @ts-ignore
        employeeId = appointment.eventTypeData.suggestedEmployee;
      }
      if (!employeeId || employeeId === 'missed') {
        return acc;
      }
      const focusKey = appointment.type.toString();
      if (!acc[focusKey]) {
        acc[focusKey] = [];
      }
      acc[focusKey].push(employeeId);
      return acc;
    }, {});

    employeeIdsByFocusMap[CalendarEventTypeEnum.APPOINTMENT] = employeesState.employees.map((employee) => employee.id);

    let focusValues = filters.focus;
    if (filters.services.length) {
      focusValues = filters.focus.filter((focusValue) => focusValue !== CalendarEventTypeEnum.APPOINTMENT);
    }

    const filterByFocus = !!focusValues.length && appointmentsState.data.length > 0;

    const filteredShifts = employeesState.shifts[dateStr].filter((shift: Shift) => {
      if (!focusValues.length && !filters.services.length) {
        return true;
      }

      let focusMatch = false;
      if (filterByFocus) {
        focusMatch = !!focusValues.find((focusValue) => employeeIdsByFocusMap[focusValue]?.includes(shift.employeeId));
      }

      let servicesMatch = false;
      if (filters.services.length) {
        servicesMatch = !!filters.services.find((service) => shift.services.includes(service.value));
      }

      return focusMatch || servicesMatch;
    });
    const offlineEvent = [] as CalendarEvent[];
    const dateStartTimestamp = date.startOf('day').valueOf();
    const dateEndTimestamp = dateStartTimestamp + 24 * 60 * 60000 - 1;
    const weekday = workingHours.weekday || date.isoWeekday();
    filteredShifts.forEach((shift) => {
      const fromTimestamp = shift.start - 3 * 60000;
      const fromDate = new Date(fromTimestamp);
      const fromMinutes = Math.floor((fromTimestamp - dateStartTimestamp) / 60000);
      const toTimestamp = shift.end + 3 * 60000;
      const toDate = new Date(toTimestamp);
      const toMinutes = Math.floor((fromTimestamp - dateStartTimestamp) / 60000);
      const duration = toMinutes - fromMinutes;

      if (fromTimestamp >= dateStartTimestamp && fromTimestamp <= dateEndTimestamp) {
        const untilEndOfDay = workingHours.to === toMinutes;
        offlineEvent.push({
          id: `working-range-${shift.employeeId}-${weekday}`,
          start: fromDate,
          startFormatted: TimeHelper.getHHmmTimeFromMinutes(fromMinutes),
          duration,
          end: toDate,
          endFormatted: TimeHelper.getHHmmTimeFromMinutes(toMinutes),
          type: CalendarEventTypeEnum.WORKING_HOURS,
          resourceId: shift.employeeId,
          isWorkingRange: true,
          untilEndOfDay,
          services: [],
        });
      }
    });
    setOfflineEvents(offlineEvent);
    setShifts(filteredShifts);
  }, [employeesState.shifts, employeesState.employees, appointmentsState.data, workingHours, timeZone, filters, dateString]);

  const roomsAmount = useMemo<RoomsAmountValues | null>(() => {
    if (storeState.loading) {
      return null;
    }
    if (!storeState.data.resources || storeState.data.resources.length < 1) {
      return null;
    }
    // TODO: We change state on component?
    // TODO: We should trust data from our servers, if resource is there it should have a name and count
    return storeState.data.resources.reduce((acc, resource) => {
      if (resource.resource?.name) {
        acc[resource.resource.name] = resource.count || 0;
      }
      return acc;
    }, {});
  }, [storeState]);

  const onDeletionWarningClose = useCallback(() => {
    setDeletionWarningState('disabled');
  }, []);

  const onDeletionErrorClose = useCallback(() => {
    setDeletionErrorState(false);
  }, []);

  const onToggleFilters = useCallback(() => {
    setIsFiltersOpened(!isFiltersOpened);
  }, [isFiltersOpened]);

  const onToggleSearch = useCallback(() => {
    navigate('/search');
  }, []);

  useEffect(() => {
    if (!employeesState.loading && missingEmployeeIds.length) {
      dispatch(getEmployeesByIds(missingEmployeeIds));
    }
  }, [missingEmployeeIds, employeesState.loading, dateString]);

  const peakTimes = useMemo(() => {
    if (!peakTimesState.data) {
      return [];
    }
    if (!workingHours) {
      return peakTimesState.data;
    }
    return peakTimesState.data.filter((item) => {
      const start = TimeHelper.getMinutesFromHHmmTimeString(item.start);
      const end = TimeHelper.getMinutesFromHHmmTimeString(item.end);
      if (end <= workingHours.from) {
        // earlier than working horus
        return false;
      }
      if (start >= workingHours.to) {
        // later than working hours
        return false;
      }
      return true;
    });
  }, [peakTimesState.data, workingHours]);

  const daySummary = useMemo(() => {
    const dateStr = TimeHelper.toStandardFormat(date);
    if (!eventStatsState.data || !eventStatsState.data[dateStr]) {
      return null;
    }
    return convertDaySummaryToCalendarDataType(eventStatsState.data[dateStr], allServices);
  }, [date, eventStatsState.data]);

  useEffect(() => {
    if (!workingHours.isLoading && !workingHours.isOpen) {
      setCalendarData({
        events: [{ loading: true }],
        resources: [{ loading: true }, { loading: true }, { loading: true }, { loading: true }, { loading: true }],
        loading: false,
      });
      return;
    }
    if (employeesState.loading || appointmentsState.loading || calendarSlotsState.loading) {
      setCalendarData({
        events: [{ loading: true }],
        resources: [{ loading: true }, { loading: true }, { loading: true }, { loading: true }, { loading: true }],
        loading: true,
      });

      return;
    }
    if (appointmentsState.data.length === 0 && Object.values(calendarSlotsState.data).length === 0) {
      setCalendarData({
        events: [{ loading: true }],
        resources: [{ loading: true }, { loading: true }, { loading: true }, { loading: true }, { loading: true }],
        loading: true,
      });

      return;
    }
    const buildedCalendarData = buildAppointmentEvents(
      appointmentsState.data,
      shifts || [],
      employeeNameById,
      allServices,
      date,
      calendarSlotsState.data,
      timeZone,
      {
        male: intl.formatMessage({ id: 'common.gender.male' }),
        female: intl.formatMessage({ id: 'common.gender.female' }),
        overflow: intl.formatMessage({ id: 'calendar.overflow' }),
      },
      filters,
      workingHours,
    );
    setCalendarData({ ...buildedCalendarData, loading: false });
  }, [appointmentsState, shifts, date, timer, allServices, calendarSlotsState, filters, workingHours]);

  const config = useMemo((): CalendarProps => {
    const startOfDay = TimeHelper.toDayjs(date.valueOf(), timeZone).startOf('day');
    const timeRange = getDayViewTimeRange(startOfDay, calendarData.events, workingHours);

    return {
      defaultDate: startOfDay.toDate(),
      min: timeRange.min,
      max: timeRange.max,
      localizer: DayjsLocalizer(dayjs, timeZone),
      formats: {
        timeGutterFormat: 'HH:mm',
        dayFormat: 'ddd DD',
      },
      toolbar: false,
      components: {
        timeGutterHeader: TimeGutterHeader,
        resourceHeader: ResourceHeader as any,
        timeSlotWrapper: TimeSlotWrapper.bind({}, peakTimes, calendarData.loading),
        eventWrapper: EventWrapper as any,
        eventContainerWrapper: EventContainerWrapper,
        day: {
          event: DayEvent as any,
        },
        header: view === 'day' ? () => <></> : undefined,
      },
      step: 30,
      timeslots: 2,
      view,
      views: {
        day: DayView,
        // week: WeekView,
      },
      onView: () => {},
      onNavigate: () => {},
      onSelectSlot: () => {},
      onSelectEvent: (event) => {
        const eventBase = event as any;
        const appointmentType = (event as CalendarEvent).type;
        if (
          [CalendarEventTypeEnum.WALKIN, CalendarEventTypeEnum.RESERVATION, CalendarEventTypeEnum.WORKING_HOURS].includes(appointmentType)
        ) {
          return;
        }
        const currentTime = dayjs.tz(Date.now(), timeZone);
        const isPastEvent = eventBase.start.getTime() < currentTime.valueOf();
        if (appointmentType === CalendarEventTypeEnum.SLOT) {
          if (!isPastEvent) {
            onCreateAppointmentClick({ services: eventBase.services, time: eventBase.start.getTime(), date: eventBase.start.getTime() });
          }
          return;
        }
        onAppointmentClick({ calendarEvent: eventBase, isPastEvent });
      },
      selectable: false,
      backgroundEvents: offlineEvents,
      date: date.toDate(),
    };
  }, [date, view, timeZone, workingHours, allServices, offlineEvents, calendarData, notLoading, peakTimes]);

  if (appointmentsState.error) {
    return <p>{intl.formatMessage({ id: 'common.error' })}</p>;
  }

  return (
    <LoadingWrapper loading={!storeState.data}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding="16px 18px">
        <Stack direction="column" className={styles.calendarContainer}>
          <Typography className={styles.currentDay}>{date.locale(localeContext?.locale || 'en').format('D. MMM, dddd')}</Typography>
          <Stack direction="row" alignItems="center">
            <DatePicker date={date} setDate={onDateChange} view={view} timeZone={timeZone} />

            {calendarData.resources.length > 0 && daySummary && (
              <DayOverview daySummary={daySummary} onSeeMore={onShowCalendarOverviewClick} />
            )}
          </Stack>
        </Stack>

        <Stack direction="row" className={styles.actionsContainer} alignItems="center">
          <RoomsAmount values={roomsAmount} />
          <IconButton onClick={onToggleFilters} className={styles.filtersIcon}>
            <Badge classes={{ dot: clsx(styles.filtersBadge, isFiltersActive && styles.activeFiltersBadge) }} variant="dot">
              <FilterIcon />
            </Badge>
          </IconButton>
          <IconButton onClick={onToggleSearch} className={styles.searchIcon}>
            <SearchIcon height={16} width={24} />
          </IconButton>
          <CreateAppointmentButton onClick={() => onCreateAppointmentClick()} />
        </Stack>
      </Stack>

      <Box className={styles.calendarWrapper}>
        {!workingHours.isLoading &&
          (workingHours.isOpen ? (
            <>
              <ReactCalendar {...config} events={calendarData.events} resources={calendarData.resources} showMultiDayTimes />
              <CalendarFooter />
            </>
          ) : (
            intl.formatMessage({ id: 'calendar.storeNotWorking' })
          ))}
      </Box>
      {isFiltersOpened && (
        <AppointmentsFilters
          isOpen={isFiltersOpened}
          onClose={onToggleFilters}
          onSave={setFilters}
          allServices={allServices}
          eventTypeCounters={calendarData.eventTypeCounters}
          values={filters}
          defaultValues={defaultFilters}
        />
      )}
      <NoticeStack>
        <Notice
          open={deletionWarningState === 'visible'}
          onClose={onDeletionWarningClose}
          severity="warning"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'calendar.appointmentIsDeleting' })}
          description={intl.formatMessage({ id: 'calendar.appointmentIsDeletingDescription' })}
        />
        <Notice
          open={!!deletionErrorState}
          onClose={onDeletionErrorClose}
          severity="error"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'common.deleteAppointmentError' })}
          description={intl.formatMessage({ id: 'common.deleteAppointmentErrorDescription' })}
        />
        <Notice
          open={slotsErrorShown}
          onClose={() => setSlotsErrorShown(false)}
          severity="error"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'slot.slotsFetchErrorTitle' })}
        />
      </NoticeStack>
    </LoadingWrapper>
  );
}
