import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SearchForm, EditAppointment, CreateAppointment, SearchFormProps } from 'features';
import { LocaleSelectContext } from 'layouts/LocaleProvider/LocaleProvider';

import styles from './index.module.scss';
import { CalendarEventCreateParams, CalendarEventEditParams } from '../../features/Appointments/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetSingleAppointment, updateSearchDataEmployees } from '../../store/appointments/slice';
import { getEmployeesByIds } from '../../store/employees/thunks';
import { selectMissingSearchEmployeeIds } from './selectors';
import { AppointmentEvent } from '../../types';

export function Search() {
  const [editAppointmentData, setEditAppointmentData] = useState<CalendarEventEditParams | undefined>(undefined);
  const [appointmentCreateData, setAppointmentCreateData] = useState<CalendarEventCreateParams | null>(null);
  const dispatch = useAppDispatch();
  const missingEmployeeIds = useAppSelector((state) => selectMissingSearchEmployeeIds(state));
  const appointmentsState = useAppSelector((state) => state.appointments);

  const localeContext = useContext(LocaleSelectContext);

  const onFormOpen: SearchFormProps['onClick'] = useCallback(({ searchCalendarEvent: { calendarEvent }, isPastItem }) => {
    dispatch(resetSingleAppointment());
    const eventTypeData = calendarEvent.eventTypeData as AppointmentEvent;

    let employeeId = '';
    if (eventTypeData && eventTypeData.suggestedEmployee) {
      employeeId = eventTypeData.suggestedEmployee;
    }

    setEditAppointmentData({
      appointmentId: calendarEvent.id,
      type: calendarEvent.type,
      employeeId,
      isDisabled: isPastItem,
    });
  }, []);

  const onFormClose = useCallback(() => {
    setEditAppointmentData(undefined);
  }, []);

  const onCopyToNewAppointment = useCallback((params: CalendarEventCreateParams) => {
    setAppointmentCreateData(params);
  }, []);

  const loadMissingEmployeeData = useCallback(async (employeeIds: string[]) => {
    const result = await dispatch(getEmployeesByIds(employeeIds));

    if (getEmployeesByIds.fulfilled.match(result)) {
      dispatch(updateSearchDataEmployees({ employees: result.payload }));
    }
  }, []);

  useEffect(() => {
    if (!appointmentsState.loading && missingEmployeeIds.length) {
      loadMissingEmployeeData(missingEmployeeIds);
    }
  }, [missingEmployeeIds, appointmentsState.loading]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeContext?.locale || 'en'}>
      <Box className={styles.container}>
        <Box className={styles.content}>
          <SearchForm onClick={onFormOpen} />
        </Box>

        {editAppointmentData && (
          <EditAppointment
            {...editAppointmentData}
            onClose={onFormClose}
            shouldReload
            onCopyToNew={onCopyToNewAppointment}
          />
        )}
        {appointmentCreateData && <CreateAppointment onClose={() => setAppointmentCreateData(null)} params={appointmentCreateData} />}
      </Box>
    </LocalizationProvider>
  );
}
