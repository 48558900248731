import React, { CSSProperties, useMemo } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { EventWrapperProps } from 'react-big-calendar';

import { CalendarEventTypeEnum, CalendarEventStatusENUM } from 'types';
import { CalendarEvent } from '../types';

import styles from './EventWrapper.module.scss';

export function EventWrapper(props: EventWrapperProps & { event: CalendarEvent }) {
  const {
    style,
    className,
    event,
    accessors,
    selected,
    getters,
    // @ts-ignore
    components,
    onClick,
  } = props;
  const title = accessors.title!(event);
  const end = accessors.end!(event);
  const start = accessors.start!(event);
  const userProps = getters.eventProp!(event, start, end, selected);

  const Event = components.event;

  const untilEndOfDay = !!event.untilEndOfDay;

  const minutes = useMemo(() => {
    if (!start || !end) {
      return 0;
    }
    return (end.valueOf() - start.valueOf()) / 60000;
  }, [start, end]);

  const pastEvent = useMemo(() => end.valueOf() - Date.now() <= 0, [end]);
  const hasNotes = useMemo(() => !!event.note, [event]);

  const calcHeight = (height?: string | number) => {
    if (event.type === CalendarEventTypeEnum.WORKING_HOURS) {
      return `${height}%`;
    }
    return `calc(${height}% - 2px)`;
  };

  const calcMargin = () => {
    if (event.type === CalendarEventTypeEnum.WORKING_HOURS) {
      return '0px';
    }
    return '2px';
  };

  const eventStyle = useMemo(
    () => ({
      top: style ? `${style.top}%` : 0,
      height: style ? calcHeight(style.height) : 0,
      minHeight: minutes <= 15 ? '16px' : '27px',
      marginTop: calcMargin(),
    }),
    [minutes, style],
  );

  const colorStyle = useMemo(() => {
    const result: CSSProperties = {};

    if (event.type === CalendarEventTypeEnum.SLOT) {
      const color = event.services[0]?.color;

      if (color) {
        result.borderColor = `${color.light} !important`;
        result.backgroundColor = `${color.background} !important`;
      }
    }

    return result;
  }, [event]);

  return (
    <Box
      style={eventStyle}
      className={clsx(styles.eventWrapper, className, userProps.className, 'calendar-event', {
        [styles.walkin]: event.type === CalendarEventTypeEnum.WALKIN,
        [styles.reserve]: event.type === CalendarEventTypeEnum.RESERVATION,
        [styles.blocker]: event.type === CalendarEventTypeEnum.BLOCKER,
        [styles.break]: event.type === CalendarEventTypeEnum.BREAK,
        [styles.task]: event.type === CalendarEventTypeEnum.TASK,
        [styles.appointment]: event.type === CalendarEventTypeEnum.APPOINTMENT,
        [styles.completed]: event.status === CalendarEventStatusENUM.COMPLETED,
        [styles.canceled]: event.status === CalendarEventStatusENUM.CANCELED,
        [styles.slot]: event.type === CalendarEventTypeEnum.SLOT,
        [styles.workingHours]: event.type === CalendarEventTypeEnum.WORKING_HOURS,
        [styles.untilEndOfDay]: untilEndOfDay,
        [styles.inactive]: pastEvent,
        [styles.cardHasNote]: event.type === CalendarEventTypeEnum.APPOINTMENT && hasNotes,
      })}
      onClick={onClick}
    >
      <Box className={clsx(styles.eventContent, 'rbc-event-content')} sx={colorStyle}>
        {Event ? <Event event={event} title={title} /> : title}
      </Box>
    </Box>
  );
}
