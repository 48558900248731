export const sortByServicesCompareFn = (current: number[], next: number[]) => {
  const currentSorted = current.includes(-1) ? [...current.filter((num) => num !== -1).sort(), ...current.filter((num) => num === -1)] : [...current].sort();
  const nextSorted = next.includes(-1) ? [...next.filter((num) => num !== -1).sort(), ...next.filter((num) => num === -1)] : [...next].sort();
  for (let i = 0; i < currentSorted.length; i += 1) {
    // if they both exist and not equal
    if (currentSorted[i] && nextSorted[i] && currentSorted[i] !== nextSorted[i]) {
      if (currentSorted[i] === -1) {
        return 1; // next goes higher
      }
      if (nextSorted[i] === -1) {
        return -1; // current goes higher
      }
      if (currentSorted[i] < nextSorted[i]) {
        return -1; // current goes higher
      }
      return 1; // next goes higher
    }
  }
  // at this point either they have equal services, or after equal part one have other services
  if (currentSorted.length === nextSorted.length) {
    return 0;
  }
  if (currentSorted.length < nextSorted.length) {
    return -1; // current goes higher
  }
  return 1;
};
