import { Avatar, FormControl, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { ArrowDown, Nullable } from '@linetweet/linetweet-ui';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import styles from './EmployeeSelectField.module.scss';

interface EmployeeSelectOption {
  id: string;
  label: string;
  iconLabel: string;
}

interface EmployeeSelectFieldProps {
  values: EmployeeSelectOption[];
  disabled?: boolean;
  value?: Nullable<string>;
  error?: boolean;
  helperText?: React.ReactNode;
  required?: boolean;
  onChange?: (value: Nullable<string>) => void;
  onBlur?: () => void;
}

export function EmployeeSelectField(
  {
    values,
    disabled,
    value: propsValue,
    error,
    required,
    helperText,
    onChange,
    onBlur,
  }: EmployeeSelectFieldProps,
) {
  const intl = useIntl();
  const value = useMemo(() => {
    if (
      propsValue == null ||
      !values.find((option) => option.id === propsValue)
    ) return '';

    return propsValue;
  }, [values, propsValue]);

  return (
    <FormControl fullWidth error={error}>
      <Select
        fullWidth
        variant="outlined"
        IconComponent={ArrowDown}
        className={clsx(styles.container, { [styles.required]: required })}
        value={value}
        onChange={(event) => onChange?.(event.target.value !== '' ? event.target.value : null)}
        disabled={disabled}
        displayEmpty
        required={required}
        onBlur={onBlur}
      >
        <MenuItem value="" key="employee-select-field-empty" selected>
          <Avatar className={styles.avatar} />
          <Typography className={styles.label}>
            {intl.formatMessage({ id: 'appointment.anyAvailableEmployee' })}
            {required && '*'}
          </Typography>
        </MenuItem>

        {values.map((valueItem) => (
          <MenuItem value={valueItem.id} key={`employee-select-field-${valueItem.id}`}>
            <Avatar className={styles.avatar}>
              <Typography className={styles.avatarText}>{valueItem.iconLabel}</Typography>
            </Avatar>
            <Typography className={styles.label}>{valueItem.label}</Typography>
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}

EmployeeSelectField.defaultProps = {
  disabled: undefined,
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  required: undefined,
  helperText: undefined,
};
