import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';

export type SpacingProps = {
  type?: 'margin' | 'padding';
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  children?: JSX.Element | JSX.Element[] | any;
};

export const Spacing: React.FC<SpacingProps> = (props) => {
  const { children, top, left, right, bottom } = props;
  const spacingType = props.type || 'margin';

  const theme = useTheme();

  const indents = useMemo(() => {
    const result: React.CSSProperties = {};

    if (top) {
      result[`${spacingType}Top`] = theme.spacing(top);
    }

    if (left) {
      result[`${spacingType}Left`] = theme.spacing(left);
    }

    if (right) {
      result[`${spacingType}Right`] = theme.spacing(right);
    }

    if (bottom) {
      result[`${spacingType}Bottom`] = theme.spacing(bottom);
    }

    return result;
  }, [top, left, right, bottom]);

  return React.cloneElement(children, { style: { ...indents } });
};
