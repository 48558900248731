import React from 'react';
import styles from './Chip.module.scss';

type Props = {
  label: string,
  title?: string
};

function Chip(props: Props) {
  const { label, title } = props;
  return (
    <div className={styles.chip} title={title}>
      {label}
    </div>
  );
}

Chip.defaultProps = {
  title: undefined,
};

export default Chip;
