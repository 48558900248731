import React, { useCallback, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { ArrowLeft, ArrowRight, Calendar as CalendarIcon } from '@linetweet/linetweet-ui';

import { TimeHelper } from 'utils';

import styles from './DatePicker.module.scss';

export function DatePicker({ view, date, setDate, timeZone }) {
  const intl = useIntl();
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const onChange = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  const setNextDate = useCallback(() => {
    setDate(TimeHelper.toDayjs(date, timeZone).add(1, view));
  }, [date, timeZone]);

  const setPrevDate = useCallback(() => {
    setDate(TimeHelper.toDayjs(date, timeZone).subtract(1, view));
  }, [date, timeZone]);

  const currentDay = TimeHelper.toDayjs(Date.now(), timeZone).startOf('day');

  const onOpen = useCallback(() => setDatePickerOpen(true), []);

  const onClose = useCallback(() => setDatePickerOpen(false), []);

  return (
    <Box display="flex" className={styles.container}>
      <IconButton className={styles.calendarButton} onClick={onOpen}>
        <CalendarIcon width={18} height={18} />
      </IconButton>
      <DesktopDatePicker
        open={datePickerOpen}
        onClose={onClose}
        inputFormat="D MMM YYYY"
        value={TimeHelper.toDayjs(date, timeZone)}
        onChange={onChange}
        disableMaskedInput
        renderInput={(params) => (
          <Typography
            fontWeight={400}
            variant="h6"
            ref={params.inputRef}
            onClick={onOpen}
            classes={{
              root: styles.label,
            }}
          >
            {/* {renderValue} */}
          </Typography>
        )}
      />
      <IconButton onClick={setPrevDate} className={styles.arrowIconButton}>
        <ArrowLeft width={18} height={18} />
      </IconButton>

      <Typography
        fontWeight={400}
        onClick={() => onChange(currentDay)}
        className={clsx(styles.todayLabel, {
          [styles.isToday]: currentDay.valueOf() === TimeHelper.toDayjs(date, timeZone).valueOf(),
        })}
      >
        {intl.formatMessage({ id: 'common.today' })}
      </Typography>
      <IconButton onClick={setNextDate} className={styles.arrowIconButton}>
        <ArrowRight width={18} height={18} />
      </IconButton>
    </Box>
  );
}
