/**
 * Creates function that caches result of `func` invocation for `ttl` milliseconds
 * Depends on current time
 */
export const cacheInvocation = <TFunc extends (...args: any) => any>(
  func: TFunc,
  ttl: number = 1000 * 60 * 1,
): TFunc => {
  let lastArgs: any | undefined;
  let lastThis: any | undefined;
  let result: any;
  let lastCallTime: number = 0;
  let lastInvokeTime: number = 0;

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime;

    return (
      lastCallTime === 0
      || (timeSinceLastCall >= ttl)
      || (timeSinceLastCall < 0)
    );
  }

  function invokeFunc(time) {
    const args = lastArgs;
    const thisArg = lastThis;

    lastArgs = undefined;
    lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);

    return result;
  }

  function debounced(this: any, ...args) {
    const time = roundUnix(Date.now(), ttl);
    const isInvoking = shouldInvoke(time);

    lastArgs = args;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      return invokeFunc(time);
    }

    return result;
  }

  return debounced as TFunc;
};

const roundUnix = (unix: number, msec: number): number => (
  Math.floor(unix / msec) * msec
);
