import { Box, Stack, Typography } from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { MicrosoftEdgeLogo } from '../../assets/icons';

import styles from './UserAgent.module.scss';

export function UserAgentWrapper({ children }: PropsWithChildren) {
  const intl = useIntl();

  const isClientSupported = useMemo<boolean>(() => {
    if (!process.env.REACT_APP_USER_AGENT_ALLOWED) {
      return true;
    }

    if (navigator?.userAgent) {
      return Boolean(new RegExp(`${process.env.REACT_APP_USER_AGENT_ALLOWED}`).exec(navigator.userAgent));
    }

    return false;
  }, []);

  if (isClientSupported) {
    return (
      <>
        { children }
      </>
    );
  }

  return (
    <Box className={styles.userAgentWrapperRoot}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={900}
        spacing={9}
      >
        <Stack
          direction="column"
          alignItems="center"
          spacing={3}
        >
          <Typography
            variant="h2"
            align="center"
            fontWeight={500}
          >
            { intl.formatMessage({ id: 'userAgent.notCompatibleTitle' }) }
          </Typography>

          <Typography
            variant="h4"
            align="center"
          >
            { intl.formatMessage({ id: 'userAgent.notCompatibleDescription' }) }
          </Typography>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          spacing={4}
        >
          <Box className={styles.browserIcon}>
            <MicrosoftEdgeLogo />
          </Box>

          <Typography
            variant="h4"
            fontWeight={600}
          >
            { intl.formatMessage({ id: 'userAgent.notCompatibleTip' }) }
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
