import React, { useCallback } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';

import { IOption, Nullable, ToggleButton } from '@linetweet/linetweet-ui';

import styles from './SlotButtonGroup.module.scss';

type Props = {
  value: Nullable<IOption>;
  onChange: (value: Nullable<IOption>) => void;
  options: IOption[];
  before?: React.ReactNode;
  isDisabled?: boolean;
};

export function SlotButtonGroup({ value, onChange, options, before, isDisabled }: Props) {
  // wrapper to reset ToggleButtonGroup inner children logic
  // eslint-disable-next-line react/require-default-props
  const Before = useCallback(({ before: beforeElement }: { before?: React.ReactNode }) => {
    if (!beforeElement) return null;

    return (
      <>
        {beforeElement}
      </>
    );
  }, []);

  return (
    <ToggleButtonGroup
      className={clsx(styles.root, { [styles.singleRow]: options.length <= 3 })}
      exclusive
      value={value}
      onChange={(event, updatedValue) => {
        const currentValue = value ? value.value : null;

        let nextValue: Nullable<IOption> = null;
        if (updatedValue !== null && updatedValue.value !== currentValue) {
          nextValue = updatedValue;
        }

        onChange(nextValue);
      }}
    >
      <Before before={before} />

      {options.map((slotItem) => (
        <ToggleButton
          key={`slots-picker-slot-${slotItem.value}`}
          variant="rounded"
          color="secondary"
          className={styles.button}
          disabled={!!isDisabled}
          selected={slotItem.value === value?.value}
          value={slotItem}
        >
          {slotItem.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
