import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';

import { Header as TopHeader, MenuButton, Nullable } from '@linetweet/linetweet-ui';
import { IMenuLocaleSelectContext } from '@linetweet/linetweet-ui/components/Header/types';

import { Reload } from 'assets/icons';
import { useLanguageOptions } from '../commons';
import { BreaksResyncProps, EpepResyncDialog } from './components/ResyncDialog';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getLogoutUrl, TimeHelper } from '../../utils';
import { resyncEpep } from '../../store/epep/thunks';
import { selectEpepState } from '../../store/epep/selectors';
import styles from './Header.module.scss';
import { selectHealthState } from '../../store/health/selectors';
import { syncBreaksToDiwa } from '../../store/appointments/thunks';
import { LocaleSelectContext } from '../../layouts/LocaleProvider/LocaleProvider';

const EPEP_TIMER = 5 * 60;

export function Header() {
  const intl = useIntl();
  const userSettings = useAppSelector((state) => state.user.settings);
  const storeState = useAppSelector((state) => state.store);
  const epepState = useAppSelector(selectEpepState);
  const appointmentsState = useAppSelector((state) => state.appointments);
  const healthState = useAppSelector(selectHealthState);

  const localeContext = useContext(LocaleSelectContext);
  if (!localeContext) {
    throw Error('LocaleSelectContext not configured');
  }

  const dispatch = useAppDispatch();

  const [appVersion, setAppVersion] = useState<Nullable<{ version: string; date: string }>>(null);
  const [epepDialogOpen, setEpepDialogOpen] = useState(false);
  const [epepTimer, setEpepTimer] = useState<number>(() => {
    const saved = localStorage.getItem('epepTimer');
    if (!saved) {
      return 0;
    }
    const startTime = parseInt(saved, 10);
    const currentTime = Date.now();
    const diff = Math.floor((currentTime - startTime) / 1000);
    if (diff > EPEP_TIMER) {
      return 0;
    }
    return EPEP_TIMER - diff;
  });
  const [breaksDialogOpen, setBreaksDialogOpen] = useState(false);
  // TODO: replace VT with real user initials from logged user
  const [userName] = useState('VT');

  const timeZone = useMemo(() => {
    if (storeState.data) {
      return storeState.data.timezone;
    }
    return '';
  }, [storeState.data]);

  const languageOptions = useLanguageOptions.default();
  const currentStore = storeState?.data || {};

  const title = currentStore.name;

  useEffect(() => {
    if (healthState.loading) {
      return;
    }
    if (healthState.versionData) {
      const { version, date } = healthState.versionData;
      const versionDate = TimeHelper.toDayjs(date, timeZone || undefined).format('DD.MM.YYYY HH:mm');
      setAppVersion({ version, date: versionDate });
    }
  }, [timeZone, healthState]);

  const signOut = useCallback(async () => {
    window.location.href = getLogoutUrl();
  }, []);

  const urls = userSettings?.urls || {};

  const onEpepDialogSubmit = useCallback(async () => {
    await dispatch(resyncEpep(storeState.data.id));
    setEpepTimer(EPEP_TIMER);
    const currentTime = Date.now().toString();
    localStorage.setItem('epepTimer', currentTime);
    setEpepDialogOpen(false);
  }, [storeState.data.id]);

  const onBreaksDialogSubmit = useCallback(async () => {
    const date = TimeHelper.toDayjs(new Date(), timeZone);
    await dispatch(syncBreaksToDiwa({ storeId: storeState.data.id, date }));
    setBreaksDialogOpen(false);
  }, [storeState.data.id, timeZone]);

  useEffect(() => {
    if (epepTimer === 0) {
      if (localStorage.getItem('epepTimer')) {
        localStorage.removeItem('epepTimer');
      }
      return;
    }
    const intervalId = setInterval(() => {
      const updatedValue = epepTimer - 1;
      setEpepTimer(updatedValue);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [epepTimer]);

  const epepTimerFormatted = useMemo(() => {
    if (epepTimer === 0) {
      return '';
    }
    const minutes = epepTimer > 60 ? Math.floor(epepTimer / 60) : '';
    const seconds = epepTimer % 60;
    const secondsWithZero = seconds < 10 && minutes ? `0${seconds}` : seconds;
    if (minutes) {
      return `${minutes}:${secondsWithZero}`;
    }
    return seconds === 0 ? '1:00' : seconds;
  }, [epepTimer]);

  return (
    <>
      <TopHeader
        title={title}
        styleClass="performance-coach"
        clock={{ show: false }}
        timeZone={timeZone}
        profile={{
          show: true,
          userName,
          urls: {
            diwa: urls?.operations,
            booking: urls?.appointments,
            performance: urls?.performanceTracker,
          },
          currentlySelected: 'appointment',
        }}
        isAppointmentsAvailable
        menu={{
          extra: (
            <>
              <Stack direction="row" className={epepTimer > 0 ? styles.epepWithTimer : styles.epepWithoutTimer}>
                <MenuButton
                  icon={<Reload className={styles.reloadEpepIcon} />}
                  label={intl.formatMessage({ id: 'menu.epepSync' })}
                  description={intl.formatMessage({ id: 'menu.epepSyncDescription' })}
                  onClick={() => setEpepDialogOpen(true)}
                />
                <Typography>{epepTimerFormatted}</Typography>
              </Stack>
              <MenuButton
                icon={<Reload className={styles.reloadBreaksIcon} />}
                label={intl.formatMessage({ id: 'menu.breaksSync' })}
                description={intl.formatMessage({ id: 'menu.breaksSyncDescription' })}
                onClick={() => setBreaksDialogOpen(true)}
              />
            </>
          ),
          localeContext: localeContext as IMenuLocaleSelectContext,
          signOut,
          languageOptions,
          appVersion: appVersion || undefined,
          isOnline: true,
          employeeSettingsLink: {
            show: false,
          },
          settingsLink: {
            show: !!userSettings?.storeSettingsEnabled,
            link: urls?.settingsPage,
          },
          showOnline: false,
          showLogout: true,
          showContacts: false,
          showLocaleSelector: true,
          translations: {
            menu: 'common.defaultMenuTitle',
            store: 'common.defaultStoreSettings',
          },
        }}
      />

      <EpepResyncDialog
        open={epepDialogOpen}
        onClose={() => setEpepDialogOpen(false)}
        onSubmit={onEpepDialogSubmit}
        submitting={epepState.loading}
      />
      <BreaksResyncProps
        open={breaksDialogOpen}
        onClose={() => setBreaksDialogOpen(false)}
        onSubmit={onBreaksDialogSubmit}
        submitting={appointmentsState.loading}
      />
    </>
  );
}
