export default {
  'common.formHasErrors': 'Please check the form for missing required fields',
  'common.from': 'from',
  'common.to': 'to',
  'common.today': 'Today',
  'common.more': 'More',
  'common.editEntity': 'Edit',
  'common.abort': 'Cancel',
  'common.delete': 'Delete',
  'common.language': 'Select',
  'common.defaultMenuTitle': 'Appointments',
  'common.signOut': 'Sign out',
  'common.defaultStoreSettings': 'Store settings',
  'common.save': 'Save',
  'common.confirm': 'Confirm',
  'common.error': 'Error',
  'common.defaultError': 'Something went wrong',
  'common.defaultErrorDescription': 'Clear cache and reload',
  'common.createAppointmentError': 'An error occurred while creating the event',
  'common.createAppointmentErrorDescription': 'The event was not created. Please check your internet connection',
  'common.deleteAppointmentError': 'An error occurred while trying to delete the event',
  'common.deleteAppointmentErrorDescription': 'The event has not been deleted. Please check your internet connection',
  'common.appVersion': 'Version',
  'common.minutesShort': '{minutes} min',
  'common.hour': 'Hour',
  'common.minutes': 'Minutes',
  'common.gender.none': '-',
  'common.gender.male': 'Mr',
  'common.gender.female': 'Ms',
  'common.search': 'Search',
  'filter.title': 'Focus on',
  'appointment.copyToNew': 'Copy information to new event',
  'appointment.information': 'Information',
  'appointment.recurringEvent': 'Recurring event',
  'appointment.recurringEventPlaceholder': 'Non-recurring',
  'appointment.lastName': 'Last name',
  'appointment.firstName': 'First name',
  'appointment.email': 'E-mail',
  'appointment.phoneNumber': 'Phone number',
  'appointment.notes': 'Notes',
  'appointment.title': 'Title',
  'appointment.preferredTimes': 'Recommended times',
  'appointment.availableTimes': 'Available times',
  'appointment.showPreferred': 'Show recommended only',
  'appointment.showAll': 'Show all',
  'appointment.appointment': 'Appointment',
  'appointment.walkin': 'Walk-in',
  'appointment.task': 'Task',
  'appointment.break': 'Break',
  'appointment.blocker': 'Blocker',
  'appointment.customer': 'Customer',
  'appointment.specificEmployee': 'Specific employee',
  'appointment.date': 'Date',
  'appointment.service': 'Service',
  'appointment.selectAllServices': 'Select all',
  'appointment.unselectAllServices': 'Unselect all',
  'appointment.anyAvailableEmployee': 'Any available employee',
  'language:dialogTitle': 'Select language',
  'language.german': 'Deutsch',
  'language.english': 'English',
  'language.french': 'Français',
  'service.visionTest': 'Eye Exam',
  'service.advisory': 'Service',
  'service.sunglasses': 'Sunglasses',
  'service.driverVisionTest': 'Eye exam for driving licence',
  'service.pickup': 'Pick-up',
  'service.service': 'Service',
  'service.workshop': 'Workshop',
  'service.serviceWorkshop': 'Workshop service',
  'service.eyeCheckUp': 'Eye-Check-Up',
  'service.augen': 'Eye-Check-Up',
  'service.KLAdjustment': 'Contact lens fitting',
  'service.KLFollowUpInspection': 'KL follow-up inspection',
  'service.serviceHA': 'Service HA',
  'service.firstConsultation': 'First consultation appointment',
  'service.shortHearingTest': 'Short hearing test',
  'service.followUpAppointment': 'Follow-up appointment',
  'service.submissionCompletion': 'Collection / completion',
  'service.postControl': 'Follow-up check',
  'service.adultPostControl': 'Advanced follow-up check',
  'service.earProtection': 'Hearing protection',
  'service.orderOptimization': 'Order optimization',
  'service.other': 'Other',
  'service.BO': 'BO',
  'service.KL': 'CL',
  'service.HA': 'HA',
  'service.followUpFitting': 'Follow-up fitting',
  'service.videoConsultation': 'Video consultation',
  'service.consultation': 'Consultation',
  'menu.operations': 'Operations',
  'menu.performance': 'Performance',
  'menu.appointments': 'Appointments',
  'menu.confirmEpepResyncTitle': 'Confirm EPEP synchronization',
  'menu.confirmEpepResyncContent': 'Please confirm the synchronization of EPEP',
  'menu.confirmBreaksResyncTitle': 'Confirm to sync breaks',
  'menu.confirmBreaksResyncContent': 'Please confirm the synchronization of the breaks',
  'menu.epepSync': 'Load ePEP data',
  'menu.epepSyncDescription': 'Click here to load recently changed data from ePEP into LTB.',
  'menu.breaksSync': 'Sync breaks',
  'menu.breaksSyncDescription': 'Click here if you would like to overwrite the break planning in DiWa with the LTB break values.',
  'calendarOverview.overflowItems': 'Conflict',
  'calendarOverview.employees': 'Employees',
  'calendarOverview.noEvents': 'No events',
  'resources.EyeTestRooms': 'Eye Exam Room',
  'resources.ContactLenses': 'CL Room',
  'resources.Horgerate': 'HA Room',
  'calendar.employee': 'Employee',
  'calendar.employeeWorkingHours': 'Work: {from} to {to}',
  'calendar.appointments': 'Appointments',
  'calendar.blockers': 'Blockers',
  'calendar.breaks': 'Breaks',
  'calendar.tasks': 'Tasks',
  'calendar.slots': 'Slots',
  'calendar.walkin': 'Walkin',
  'calendar.deleteAppoinctmentDialog': 'Do you really want to delete? This action cannot be undone.',
  'calendar.deleteAppointmentDialogTitle': 'Are you sure?',
  'calendar.walkinTitle': 'Reserved for walk-ins',
  'calendar.reserveTitle': 'Reserved',
  'calendar.createAppointment': 'Add new appointment',
  'calendar.appointmentIsDeleting': 'Event will be deleted',
  'calendar.appointmentIsDeletingDescription': 'Please wait for the update',
  'calendar.submit': 'Apply',
  'calendar.storeNotWorking': 'The strore is closed in selected date.',
  'calendar.overflow': 'Overflow',
  'search.title': 'Search',
  'search.noResults': 'No Results',
  'search.noName': 'No name',
  'calendarEvent.employee.noSlotsAvailableError': 'There are no free slots available for selected employee',
  'userAgent.notCompatibleTitle': 'Not compatible',
  'userAgent.notCompatibleDescription': 'Your current browser is not compatible with LineTweet Booking (LTB). Please use Edge',
  'userAgent.notCompatibleTip': 'Use Edge',
  'slot.slotsFetchErrorTitle': 'An error happened when trying to get slots, can you please try again',
};
