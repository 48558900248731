import React, { useEffect, useCallback, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { VirtualListProps } from './types';

import styles from './index.module.scss';

export function VirtualList<T>(props: VirtualListProps<T>) {
  const {
    renderRaw,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    data,
    overscan,
    scrollToIndex,
    estimateSize,
    parentHeight,
    hideScroll,
    onMove,
  } = props;

  const parentRef = React.useRef<HTMLDivElement>(null);
  const [startY, setStartY] = useState(null);

  const handleTouchStart = (e) => {
    if (!onMove) return;

    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!onMove) return;

    if (!startY !== null) {
      const currentY = e.touches[0].clientY;

      // @ts-ignore
      if (currentY > startY) {
        onMove('top');
      } else {
        onMove('bottom');
      }
    }
  };
  const handleTouchEnd = () => {
    if (!onMove) return;

    setStartY(null);
  };

  const virtualizer = useVirtualizer({
    count: hasNextPage ? data.length + 1 : data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimateSize,
    overscan,
  });

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= data.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    data.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  useEffect(() => {
    if (scrollToIndex == null) return;

    virtualizer.scrollToIndex(scrollToIndex);
  }, [scrollToIndex]);

  const items = virtualizer.getVirtualItems();

  const handleWheel = useCallback((wheelEvent) => {
    if (!onMove) return;

    if (wheelEvent.deltaY > 0) {
      onMove('bottom');
    } else {
      onMove('top');
    }
  }, [onMove]);

  return (
    <Box
      {...{
        ...(onMove ? {
          onWheel: handleWheel,
          onTouchStart: handleTouchStart,
          onTouchMove: handleTouchMove,
          onTouchEnd: handleTouchEnd,
        } : {}),
        ref: parentRef,
        className: clsx({
          [styles.hideScroll]: hideScroll,
        }),
        style: {
          height: parentHeight || '100vh',
          overflowY: 'auto',
          contain: 'strict',
        },
      }}
    >
      <Box
        style={{
          height: virtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${items.length ? items[0].start : 0}px)`,
          }}
        >
          {items.map((virtualRow) => {
            const isLoaderRow = virtualRow.index > data.length - 1;
            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                {!isLoaderRow && renderRaw(data[virtualRow.index], virtualRow.index, data)}
              </div>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
