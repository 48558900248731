import React from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ViewStatic } from 'react-big-calendar';

import { TimeHelper } from 'utils';

import styles from './WeekView.module.scss';

// eslint-disable-next-line react/function-component-definition
export const WeekView: React.FC<any> & ViewStatic & { range: (date: Date, options: any) => Date[] } = (props) => {
  const {
    localizer,
    min = localizer.startOf(new Date(), 'day'),
    max = localizer.endOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day'),
    enableAutoScroll = true,
    peakTimes,
    date,
    events,
    ...restProps
  } = props;

  const dates = WeekView.range(date as Date, props);
  const startOfTheWeek = dates[0];

  return (
    <Box display="flex" className={styles.weekViewContainer}>
      {dates.map((dateItem) => {
        const dateSectionDayjs = dayjs(dateItem);
        const startOfDay = dayjs(dateItem).startOf('day');
        const endOfDay = dayjs(dateItem).endOf('day');
        const weekDay = startOfDay.day();
        const eventsForCurrentDate = events.filter((e) => e.start >= startOfDay && e.end <= endOfDay);

        return (
          <Box
            key={date.valueOf()}
            className={clsx(styles.weekDayWrapper, {
              [styles.hideTimeSlots]: startOfTheWeek !== date,
            })}
          >
            <Box display="flex">
              {startOfTheWeek === date && <Box className={styles.dayTitle} />}
              <Box className={styles.daySection}>
                <Typography fontWeight={500} marginRight={1}>
                  {dateSectionDayjs.format('ddd')},
                </Typography>
                <Typography>{dateSectionDayjs.format('D MMM')}</Typography>
              </Box>
            </Box>
            <TimeGrid
              {...restProps}
              events={eventsForCurrentDate}
              resources={props.resources.map((resource) => {
                resource.weekDay = weekDay;
                return resource;
              })}
              range={[date]}
              eventOffset={15}
              localizer={localizer}
              min={min}
              max={max}
              scrollToTime={scrollToTime}
              enableAutoScroll={enableAutoScroll}
            />
          </Box>
        );
      })}
    </Box>
  );
};

WeekView.navigate = (date) => date;
WeekView.title = () => '';
WeekView.range = (date, timezone) => TimeHelper.getWeekdays(TimeHelper.toDayjs(date, timezone)).map((day) => day.toDate());
