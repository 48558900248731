import React, { PropsWithChildren } from 'react';
import { ThemeProvider, createTheme, PaletteColor } from '@mui/material';
import { defaultTheme } from '@linetweet/linetweet-ui';

interface InputColorOptions {
  background: string;
  hover: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    accent: PaletteColor;
    neutral: PaletteColor;
    neutralDark: PaletteColor;
    input: InputColorOptions;
  }

  interface TypeBackground {
    input: React.CSSProperties['color'];
    neutral: React.CSSProperties['color'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    accent: PaletteColor;
    neutral: PaletteColor;
    neutralDark: PaletteColor;
    input: InputColorOptions;
  }

  interface TypographyVariants {
    input: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    input?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    neutralDark: true;
  }

  interface ButtonPropsVariantOverrides {
    rounded: true;
  }
}

declare module '@mui/material/ToggleButton' {
  export interface ToggleButtonPropsColorOverrides {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    input: true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    neutral: true;
  }

  interface AlertPropsVariantOverrides {
    transparent: true;
  }
}

declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    transparent: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}

type MainContainerProps = {};

const theme = createTheme(defaultTheme);

export const Theme: React.FC<PropsWithChildren<MainContainerProps>> = function fun({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
