import { Dayjs } from 'dayjs';
import { TimeHelper } from '../../utils';
import { CalendarEvent } from './types';

export function isBreakpoint(eventMinutes: number, breakpointMinutes: 5 | 10 | 15 | 20 | 25 | 30 | 45 | 60 | 120) {
  return eventMinutes >= breakpointMinutes;
}

export function getDayViewTimeRange(
  startOfDay: Dayjs,
  calendarEvents: CalendarEvent[],
  workingHours: { from: number; to: number },
): { min: Date; max: Date } {
  const storeOpeningTime = startOfDay.clone().add(workingHours.from, 'minutes').startOf('hour').toDate();
  const storeClosingTime = startOfDay.clone().add(workingHours.to, 'minutes').startOf('hour').toDate();

  const timeRange = { min: storeOpeningTime, max: storeClosingTime };

  calendarEvents.forEach((calendarEvent) => {
    if (calendarEvent.start && calendarEvent.start.getTime() < timeRange.min.getTime()) {
      timeRange.min = calendarEvent.start;
    }

    if (calendarEvent.end && calendarEvent.end.getTime() > timeRange.max.getTime()) {
      timeRange.max = calendarEvent.end;
    }
  });

  const startOfDayDate = startOfDay.toDate();
  const endOfDayDate = startOfDay.endOf('day').toDate();

  timeRange.min = TimeHelper.toDayjs(timeRange.min).startOf('hour').subtract(1, 'hour').toDate();
  timeRange.max = TimeHelper.toDayjs(timeRange.max).startOf('hour').add(1, 'hour').toDate();

  if (timeRange.min.getTime() < startOfDayDate.getTime()) {
    timeRange.min = startOfDayDate;
  }

  if (timeRange.max.getTime() > endOfDayDate.getTime()) {
    timeRange.max = endOfDayDate;
  }

  return timeRange;
}
