import React, { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import dayjs from 'dayjs';

import {
  CalendarCompact as AppointmentIcon,
  TaskRhombusIcon as TaskIcon,
  EditAppointment as EditAppointmentIcon,
  BreakTime as BreakIcon,
  BlockerIcon,
  WalkinIcon,
} from '@linetweet/linetweet-ui';

import { AppointmentEvent, CalendarEventTypeEnum, Service, SearchCalendarEvent } from '../../../types';
import { getServiceIcon, parseEmployeeNameWithEmoji } from '../../../utils';
import { GetAppointmentCustomer, GetAppointmentServices } from '../../commons';

import styles from '../index.module.scss';
import { getFullNameWithPrefix } from '../../commons/getFullNameWithPrefix';

export type SearchItemProps = {
  appointmentData: SearchCalendarEvent;
  allServices: Service[];
  timeZone: string;
  onClick: (params: { searchCalendarEvent: SearchCalendarEvent; isPastItem: boolean }) => void;
};

export function SearchItem({ appointmentData, allServices, timeZone, onClick }: SearchItemProps) {
  const { calendarEvent, isFirstInDay, isLastInDay, day, dateTime } = appointmentData;
  const intl = useIntl();
  const formattedDay = day.format('DD.MM.YYYY');

  const employeeName = useMemo(() => {
    const { employee } = calendarEvent;
    if (!employee) {
      return null;
    }
    const { firstName, lastName } = employee;
    const { fullName } = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);

    return fullName;
  }, [calendarEvent.employee]);

  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);
  const customer = GetAppointmentCustomer(calendarEvent);
  const startTime = day.add(calendarEvent.time, 'minutes').format('HH:mm');
  const endTime = day.add(calendarEvent.time + calendarEvent.duration, 'minutes').format('HH:mm');

  const isClickable = calendarEvent.type && [
    CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK,
  ].includes(calendarEvent.type);

  const title = useMemo(() => {
    if (!customer) {
      return calendarEvent.title;
    }

    const prefix = customer.gender
      ? `${intl.formatMessage({ id: `common.gender.${customer.gender}` })}.`
      : '';

    return getFullNameWithPrefix(customer, prefix) || calendarEvent.title;
  }, [customer, calendarEvent.title]);

  const onClickHandler = useCallback(() => {
    const currentTime = dayjs.tz(Date.now(), timeZone);
    const isPastItem = dateTime < currentTime.valueOf();
    onClick({ searchCalendarEvent: appointmentData, isPastItem });
  }, [calendarEvent, dateTime, timeZone]);

  return (
    <>
      {isFirstInDay && <Typography className={styles.date}>{formattedDay}</Typography>}
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        className={clsx(styles.card, {
          [styles.clickable]: isClickable,
          [styles.isFirstInDay]: isFirstInDay,
          [styles.isLastInDay]: isLastInDay,
        })}
        key={`grouped-appointments-${formattedDay}-${calendarEvent.id}`}
        onClick={isClickable ? () => onClickHandler() : undefined}
      >
        {calendarEvent.type === CalendarEventTypeEnum.APPOINTMENT && (
          <AppointmentIcon className={styles.appointmentIcon} />
        )}
        {calendarEvent.type === CalendarEventTypeEnum.TASK && (
          <TaskIcon className={styles.taskIcon} />
        )}
        {calendarEvent.type === CalendarEventTypeEnum.WALKIN && (
          <WalkinIcon className={styles.walkinIcon} />
        )}
        {calendarEvent.type === CalendarEventTypeEnum.BREAK && (
          <BreakIcon className={styles.breakIcon} />
        )}
        {calendarEvent.type === CalendarEventTypeEnum.BLOCKER && (
          <BlockerIcon className={styles.blockerIcon} />
        )}
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" className={styles.textContainer}>
          <Typography className={styles.name}>
            {title}
          </Typography>
          <Typography className={styles.interval}>
            {day.format('dddd, MMM D')} <span>&#183;</span> {startTime} - {endTime}
          </Typography>
          {employeeName && (
            <Typography className={styles.employee}>
              {intl.formatMessage({ id: 'calendarOverview.employees' })}: {employeeName}
            </Typography>
          )}
          {calendarEvent.note && (
            <Typography className={styles.note}>
              {intl.formatMessage({ id: 'appointment.notes' })}: {calendarEvent.note}
            </Typography>
          )}
          {customer && (
            <>
              {customer.phoneNumber && (
                <Typography className={styles.note}>
                  {intl.formatMessage({ id: 'appointment.phoneNumber' })}: {customer.phoneNumber}
                </Typography>
              )}
              {customer.email && (
                <Typography className={styles.note}>
                  {intl.formatMessage({ id: 'appointment.email' })}: {customer.email}
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" className={styles.serviceList}>
          <div className={styles.services}>
            {services.map((service) => (
              <div
                key={service.id}
                className={styles.serviceWrapper}
              >
                {getServiceIcon(service.icon)}
              </div>
            ))}
          </div>
          {isClickable && (
            <EditAppointmentIcon className={styles.editIcon} />
          )}
        </Stack>
      </Stack>
    </>
  );
}
