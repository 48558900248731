import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import { EVENT_STATS_FIND_ERROR } from '../types/calendarOverview';
import { apiClient } from '../../utils/CLIENT';

export const getEventStats = createAsyncThunk('eventStats/get', async (payload: {
  from: Dayjs;
  to: Dayjs;
  storeId: string;
  enterpriseId: string;
}) => {
  const from = payload.from.format('YYYY-MM-DD');
  const to = payload.to.format('YYYY-MM-DD');

  const response = await apiClient.get('/lt-booking/event-stats', {
    params: {
      from,
      to,
      enterpriseId: payload.enterpriseId,
    },
  });

  if (!response || !response.data) {
    throw new Error(EVENT_STATS_FIND_ERROR);
  }

  return response.data;
});
