import { useIntl } from 'react-intl';

import { LocaleKeyEnum } from 'types';

export const languages: IOption<LocaleKeyEnum>[] = [
  { label: 'language.german', value: 'de-DE' as LocaleKeyEnum },
  { label: 'language.english', value: 'en-US' as LocaleKeyEnum },
  { label: 'language.french', value: 'fr-FR' as LocaleKeyEnum },
];

export interface IOption<TValue = string | number> {
  label?: string;
  value: TValue;
}

const useLanguageOptions = (): IOption<LocaleKeyEnum>[] => {
  const intl = useIntl();

  return languages.map((language) => ({
    ...language,
    label: intl.formatMessage({ id: language.label }),
  }));
};

export default useLanguageOptions;
