export function getApiUrl() {
  return `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_PATH}`;
}

export function getLoginUrl() {
  return `${getApiUrl()}${process.env.REACT_APP_LOGIN_PAGE}`;
}

export function getLogoutUrl() {
  return `${getApiUrl()}${process.env.REACT_APP_LOGOUT_PAGE}`;
}
