import { createAsyncThunk } from '@reduxjs/toolkit';
import { socketIo } from '../../utils/socketIo';

export const listenDateChanges = createAsyncThunk('global/listen-date-changes', async (date: string) => {
  try {
    await socketIo.emit('join_date', { date });
  } catch (error) {
    throw error;
  }

  return date;
});
