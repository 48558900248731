import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestData } from '../../utils/socketIo';

export const getUser = createAsyncThunk(
  'user/get',
  async (): Promise<{
    id: string,
    store: string
  }> => {
    const user = await requestData<{ id: string, store: string }>('user:get');
    if (!user || !user.id || !user.store) {
      throw new Error('user is not configured');
    }

    return {
      id: user.id,
      store: user.store,
    };
  },
);

export const getUserSettings = createAsyncThunk(
  'user/getSettings',
  async () => {
    const settings = await requestData<{ id: string, store: string }>('user-settings:get');
    return settings || {};
  },
);
