import { createSlice } from '@reduxjs/toolkit';
import { getVersion } from './thunks';

interface InitialState {
  versionData: { version: string, date: number } | null;
  loading: boolean;
  error: any;
}

const initialState: InitialState = {
  versionData: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'health',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVersion.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVersion.fulfilled, (state, action) => {
      if (action.payload) {
        state.versionData = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(getVersion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default slice.reducer;
