import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AppointmentEvent } from '../../types';

export const selectMissingSearchEmployeeIds = createSelector(
  [
    (state: RootState) => state.appointments,
  ],
  (appointmentsState) => (
    appointmentsState.searchData.reduce<string[]>((acc, appointment) => {
      const suggestedEmployeeId = (appointment.eventTypeData as AppointmentEvent)?.suggestedEmployee;
      if (suggestedEmployeeId && !appointment.employee) {
        acc.push(suggestedEmployeeId);
      }
      return acc;
    }, [])
  ),
);
