import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import CLIENT from '../../utils/CLIENT';
import { TimeHelper } from '../../utils';

export const fetchPeakTimes = createAsyncThunk(
  'stores/peakTimes',
  async (payload: { date: Dayjs; timeZone: string, storeId: string, enterpriseId: string }) => {
    const date = TimeHelper.toDayjs(payload.date.format('YYYY-MM-DD'), payload.timeZone);
    const query = [
      `date=${TimeHelper.toStandardFormat(date)}`,
      `storeId=${payload.storeId}`,
      `enterpriseId=${payload.enterpriseId}`,
    ];
    const response = await CLIENT({
      url: `/stores/peak-times?${query.join('&')}`,
      method: 'GET',
    });

    if (response.data.error) {
      throw new Error('Peak times not found');
    }

    for (let i = 0; i < response.data.length; i += 1) {
      const { start, end } = response.data[i];
      response.data[i].start = TimeHelper.toDayjs(start, payload.timeZone).format('HH:mm');
      response.data[i].end = TimeHelper.toDayjs(end, payload.timeZone).format('HH:mm');
    }
    return response.data;
  },
);
