import React from 'react';
import { useIntl } from 'react-intl';

import { ResyncDialog } from './ResyncDialog';

type EpepResyncDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
};

export const EpepResyncDialog: React.FC<EpepResyncDialogProps> = (props) => {
  const intl = useIntl();

  return (
    <ResyncDialog
      {...props}
      title={intl.formatMessage({ id: 'menu.confirmEpepResyncTitle' })}
      content={intl.formatMessage({ id: 'menu.confirmEpepResyncContent' })}
    />
  );
};
