import { createAsyncThunk } from '@reduxjs/toolkit';
import CLIENT from '../../utils/CLIENT';

export const resyncEpep = createAsyncThunk('resyncEpep', async (storeId: string) => {
  const response = await CLIENT({
    url: `/stores/resyncEpep?storeId=${storeId}`,
    method: 'GET',
  });

  if (response.data.error) {
    throw new Error('Epep sync error');
  }

  return response.data;
});
