import React, { ReactElement, ReactNode } from 'react';

import * as icons from '@linetweet/linetweet-ui';

export const getResourceIcon = (icon: string, className: string): ReactNode => {
  if (!icon) {
    return null;
  }
  const IconComponent = icons[icon];
  if (!IconComponent) {
    return null;
  }
  return React.cloneElement(IconComponent() as ReactElement, { key: `resource-icon-svg-${icon}`, className });
};
