import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DialogSection } from '@linetweet/linetweet-ui';
import { useIntl } from 'react-intl';

import { Service } from 'types';
import { ICategory, ServiceTabSelect } from '../../commons';
import { ICalendarEventFormValues, ServiceTabsSelectOption } from '../types';

type Props = {
  services: Service[];
  defaultValues: ServiceTabsSelectOption[];
  isDisabled?: boolean;
};

export function AppointmentFormServiceSection({ services, defaultValues, isDisabled }: Props) {
  const intl = useIntl();
  const form = useFormContext<ICalendarEventFormValues>();

  let defaultTabId = '';
  if (defaultValues.length > 0) {
    defaultTabId = defaultValues[0].tabId;
  } else if (services.length > 0) {
    defaultTabId = services[0].category;
  }
  const [activeTabId, setActiveTabId] = useState(defaultTabId);

  const serviceInputProps = useMemo(() => {
    const tabs = services.reduce((acc: ICategory[], service) => {
      if (acc.find((category) => service.category === category.id)) {
        return acc;
      }
      acc.push(
        {
          id: service.category,
          name: intl.formatMessage({ id: `service.${service.category}` }),
        },
      );
      return acc;
    }, []);

    const options = services.map(
      (service): ServiceTabsSelectOption => ({
        label: intl.formatMessage({ id: service.name }),
        value: service.id,
        name: service.name,
        tabId: service.category,
        extra: { ...service },
      }),
    );

    return {
      tabs,
      options,
    };
  }, [services]);

  return (
    <DialogSection
      title={intl.formatMessage({ id: 'service.service' })}
    >
      <Controller
        control={form.control}
        name="services"
        render={({ field }) => (
          <ServiceTabSelect
            {...serviceInputProps}
            exclusive
            isDisabled={!!isDisabled}
            value={field.value}
            onServiceChange={field.onChange}
            onTabChange={setActiveTabId}
            activeTabId={activeTabId}
          />
        )}
      />
    </DialogSection>
  );
}
