import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';

export const selectStoreServices = createSelector((state: RootState) => state.store, (store) => {
  if (store.loading) {
    return [];
  }
  if (typeof store.data === 'undefined') {
    return [];
  }
  if (!store.data.services) {
    return [];
  }
  const settingsByServiceId = store.data.serviceSettings ? store.data.serviceSettings.reduce((acc, settings) => {
    acc[settings.service] = settings;
    return acc;
  }, {}) : {};
  return store.data.services.map((service) => {
    const settings = settingsByServiceId[service.id];
    if (!settings) {
      return service;
    }
    const overrideSettigs = Object.entries(settings).reduce((acc, [fieldName, fieldValue]) => {
      if (fieldName !== 'id' && typeof service[fieldName] !== 'undefined') {
        acc[fieldName] = fieldValue;
      }
      return acc;
    }, {});
    return {
      ...service,
      ...overrideSettigs,
    };
  });
});
