import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export function AsyncButton(
  {
    children,
    ...props
  }: LoadingButtonProps,
) {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const propOnClick = props.onClick;
  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      try {
        setIsLoading(true);
        await propOnClick?.(event);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
      }
    },
    [propOnClick],
  );

  return (
    <LoadingButton {...props} ref={ref} loading={isLoading} onClick={onClick}>
      {children}
    </LoadingButton>
  );
}
