import { AnyObject, Maybe, ObjectSchema } from 'yup';

export const isRequired = (schema: ObjectSchema<Maybe<AnyObject>>, fieldName: string): boolean => {
  if (!schema) return false;

  const field = schema.fields[fieldName] as ObjectSchema<Maybe<AnyObject>>;

  return (
    schema && !field.spec.optional && !field.spec.nullable
  );
};
