import { createAsyncThunk } from '@reduxjs/toolkit';
import { TimeHelper } from '../../utils';
import { apiClient } from '../../utils/CLIENT';

export const getStore = createAsyncThunk('store/get', async () => {
  const result = await apiClient.get('/lt-booking/store');

  if (!result || !result.data) {
    throw new Error('store was not found');
  }

  const store = result.data;

  const { timezone } = store;
  if (store.hours) {
    for (let i = 0; i < store.hours.regular.length; i += 1) {
      TimeHelper.convertUtcHoursToTimezoneHours(store.hours.regular[i], timezone);
    }
    for (let i = 0; i < store.hours.specialDates.length; i += 1) {
      TimeHelper.convertUtcHoursToTimezoneHours(store.hours.specialDates[i], timezone);
    }
  }
  if (store.settings && store.settings.breakIntervals && store.settings.breakIntervals.length > 0) {
    const baseDate = TimeHelper.toUtcDayjs(Date.now()).startOf('day').valueOf();
    const date = TimeHelper.toDayjs(baseDate, timezone);
    store.settings.breakIntervals = store.settings.breakIntervals.map((breakInterval) => {
      const from = TimeHelper.convertToMinutesFromDayStart(date.add(breakInterval[0], 'minutes'));
      const to = TimeHelper.convertToMinutesFromDayStart(date.add(breakInterval[1], 'minutes'));
      return [from, to];
    });
  }
  return store;
});
