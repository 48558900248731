import { createSlice } from '@reduxjs/toolkit';

export enum ConnectionType {
  Disconnected,
  InProgress,
  Connected,
}

interface InitialState {
  connectionStatus?: ConnectionType;
}

const initialState: InitialState = {};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    connect: (state) => {
      state.connectionStatus = ConnectionType.InProgress;
    },
    disconnect: (state) => {
      state.connectionStatus = ConnectionType.Disconnected;
    },
    connected: (state) => {
      state.connectionStatus = ConnectionType.Connected;
    },
  },
});

export const { connect, connected, disconnect } = slice.actions;
export default slice.reducer;
