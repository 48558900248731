import React, { useEffect } from 'react';
import { LoadingWrapper } from '@linetweet/linetweet-ui';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { connect } from '../store/global/slice';

export function AuthWrapper() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: any) => state.user);

  useEffect(() => {
    dispatch(connect());
  }, []);

  if (user === null) {
    return <div>Access forbidden</div>;
  }

  return (
    <LoadingWrapper loading={user === undefined}>
      <Outlet />
    </LoadingWrapper>
  );
}
