import React from 'react';
import { CalendarEventTypeEnum } from 'types';
import { CustomEventProps } from '../types';
import { DayEventBase, DayEventColored } from '../subcomponents';

export function DayEvent(props: CustomEventProps) {
  const { event } = props;

  if (event.type === CalendarEventTypeEnum.SLOT) {
    return <DayEventColored {...props} />;
  }

  return (
    <DayEventBase {...props} />
  );
}
