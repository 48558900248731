import React from 'react';
import { ToggleButtonGroup } from '@mui/material';

import { Nullable, ToggleButton } from '@linetweet/linetweet-ui';
import { useIntl } from 'react-intl';

import styles from './GenderButtonGroup.module.scss';

interface GenderButtonGroupProps {
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
  disabled?: boolean;
}

export function GenderButtonGroup({ value, onChange, disabled }: GenderButtonGroupProps) {
  const intl = useIntl();
  const options = [
    {
      label: intl.formatMessage({ id: 'common.gender.none' }),
      value: null,
    },
    {
      label: intl.formatMessage({ id: 'common.gender.female' }),
      value: 'female',
    },
    {
      label: intl.formatMessage({ id: 'common.gender.male' }),
      value: 'male',
    },
  ];

  return (
    <ToggleButtonGroup
      exclusive
      disabled={!!disabled}
      className={styles.container}
      value={value || ''}
      onChange={(_event, changedValue: string) => {
        onChange(changedValue || null);
      }}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} color="primary" value={option.value || ''}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
