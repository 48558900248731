import { DialogSection } from '@linetweet/linetweet-ui';
import React from 'react';
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AnyObject, Maybe, ObjectSchema } from 'yup';

import { CustomerNameInput, OutlinedInput } from '../subcomponents';
import { ICalendarEventFormValues } from '../types';

type Props = {
  titleDisabled?: boolean;
  isDisabled?: boolean;
  schema: ObjectSchema<Maybe<AnyObject>>
};

export function AppointmentFormInformationSection({ titleDisabled, isDisabled, schema }: Props) {
  const form = useFormContext<ICalendarEventFormValues>();
  const intl = useIntl();

  return (
    <DialogSection
      title={intl.formatMessage({ id: 'appointment.information' })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState }) => (
              <CustomerNameInput
                placeholder={intl.formatMessage({ id: 'appointment.title' })}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={titleDisabled || !!isDisabled}
                required
                error={!!fieldState.error}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="note"
            render={({ field }) => (
              <OutlinedInput
                placeholder={intl.formatMessage({ id: 'appointment.notes' })}
                disabled={!!isDisabled}
                value={field.value || ''}
                multiline
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </DialogSection>
  );
}

AppointmentFormInformationSection.defaultProps = {
  titleDisabled: undefined,
  isDisabled: undefined,
};
