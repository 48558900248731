import { createSlice } from '@reduxjs/toolkit';
import { getStore } from './thunks';
import { Resource, Service } from '../../types';

interface InitialState {
  loading: boolean;
  error: any;
  data: any;
  enterpriseServices?: Service[];
  enterpriseResources?: Resource[];
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: {},
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getStore.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });
    builder.addCase(getStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error; // todo check it
    });
  },
});

export default slice.reducer;
