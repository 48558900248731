import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import dayjs from 'dayjs';
import { DialogHeader, DialogSection, IOption, IOptionExtra, Notice, NoticeStack, Nullable, Paper, ToggleButton } from '@linetweet/linetweet-ui';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Dialog, DialogContent, FormHelperText, Grid, Stack, ToggleButtonGroup } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isEmpty, sortBy } from 'lodash';
import { useIntl } from 'react-intl';

import { parseEmployeeNameWithEmoji, TimeHelper } from 'utils';
import { CalendarEventTypeEnum, Employee, Service, Store } from 'types';
import { AsyncButton, EmployeeSelectField, Spin } from 'features/commons';
import { createCalendarEvents } from 'store/appointments/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSlots, getSlotsByDate } from 'store/slots/thunks';
import { fetchEmployees } from 'store/employees/thunks';
import { selectAvailableDates } from 'store/slots/selectors';
import { resetDaySlots, resetSlots } from 'store/slots/slice';
import { IGetSlotsByDatesPayload } from 'store/slots/types';
import {
  AppointmentFormCustomerSection,
  AppointmentFormInformationSection,
  AppointmentFormRecurringSection,
  AppointmentFormServiceSection,
  AppointmentFormTimeSection,
  PreferredSlotSelectSection,
} from './components';
import {
  calendarEventFormValuesToCreateCalendarEventParams,
  createSlotOptions,
  getCreateCalendarEventFormDefaultValues,
  getDateRange,
} from './helpers';
import { AppointmentFormSlotItem, CalendarEventCreateParams, ICalendarEventFormValues } from './types';
import { calendarEventFormSchema } from './util/calendarEventFormSchema';

import styles from './CreateAppointment.module.scss';
import { selectAvailableEmployees, selectSlotEmployeesByServiceIds } from '../../store/employees/selectors';
import { selectStoreServices } from '../../store/store/selectors';
import { CustomDay } from './subcomponents';
import { isRequired } from './util/isRequired';
import { resetCreateCalendarEvent } from '../../store/appointments/slice';

type Props = {
  onClose: () => void;
  params: CalendarEventCreateParams;
};

const appointmentTypeOptions: (IOption & IOptionExtra<{ disabled?: boolean }>)[] = [
  {
    value: CalendarEventTypeEnum.APPOINTMENT,
    label: 'appointment.appointment',
  },
  {
    value: CalendarEventTypeEnum.BREAK,
    label: 'appointment.break',
  },
  {
    value: CalendarEventTypeEnum.BLOCKER,
    label: 'appointment.blocker',
  },
];

export function CreateAppointment({ onClose, params }: Props) {
  const { date, time, services, employeeId } = params;
  const intl = useIntl();
  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);
  const createCalendarEventState = useAppSelector((state) => state.appointments.createCalendarEvent, shallowEqual);
  const timeZone = useMemo(() => storeState.data?.timeZone || 'Europe/Berlin', [storeState]);
  const dateObj = useMemo(() => {
    if (!date) {
      return undefined;
    }
    return TimeHelper.toDayjs(date, timeZone);
  }, [timeZone, date]);
  const timeObj = useMemo(() => {
    if (!time) {
      return undefined;
    }
    return TimeHelper.toDayjs(time, timeZone);
  }, [timeZone, date]);

  const [store, setStore] = useState<Store | undefined>();
  const [isErrorNoticeShown, setIsErrorNoticeShown] = useState(false);
  const [slotsErrorShown, setSlotsErrorShown] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (storeState.loading || storeState.error) {
      return;
    }
    setStore(storeState.data);
  }, [storeState]);

  const defaultValues = useMemo(() => {
    const servicesValues = services
      ? services.map((service) => ({
          label: intl.formatMessage({ id: service.name }),
          value: service.id,
          name: service.name,
          tabId: service.category,
          extra: { ...service },
        }))
      : [];
    const formattedTime = timeObj ? timeObj.format('HH:mm') : undefined;
    return getCreateCalendarEventFormDefaultValues({
      ...params,
      date: dateObj ? dateObj.format('YYYY-MM-DD') : undefined,
      slot: formattedTime ? { label: formattedTime, value: TimeHelper.getMinutesFromHHmmTimeString(formattedTime) } : undefined,
      services: servicesValues,
      employeeId,
    });
  }, [timeObj, dateObj, services]);

  const form = useForm<ICalendarEventFormValues>({
    defaultValues,
    resolver: yupResolver(calendarEventFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const typeValue = useWatch({ control: form.control, name: 'type' });
  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const dateValue = useWatch({ control: form.control, name: 'date' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });
  const slotValue = useWatch({ control: form.control, name: 'slot' });

  const isServiceSectionShown = typeValue === CalendarEventTypeEnum.APPOINTMENT;
  const isInformationSectionShown = [CalendarEventTypeEnum.BREAK, CalendarEventTypeEnum.BLOCKER].includes(typeValue);
  const isCustomerSectionShown = [CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(typeValue);
  const isRecurringSectionShown = [CalendarEventTypeEnum.BREAK, CalendarEventTypeEnum.TASK].includes(typeValue);
  const isSlotSectionShown = typeValue === CalendarEventTypeEnum.APPOINTMENT && Boolean(servicesValue.length);
  const isTimeSectionShown = [CalendarEventTypeEnum.BREAK, CalendarEventTypeEnum.BLOCKER, CalendarEventTypeEnum.TASK].includes(typeValue);

  const isEmployeeRequired = [
    CalendarEventTypeEnum.BLOCKER,
    CalendarEventTypeEnum.BREAK,
  ].includes(typeValue)
    && isRequired(calendarEventFormSchema, 'employee');

  const serviceIds = useMemo(() => servicesValue.map(({ value }) => value), [servicesValue]);

  const availableDays = useAppSelector((state) => selectAvailableDates(state, serviceIds, employeeValue), shallowEqual);
  const slotEmployees = useAppSelector((state) => selectSlotEmployeesByServiceIds(state, serviceIds, dateValue), shallowEqual);

  const onErrorNoticeClose = useCallback(() => {
    setIsErrorNoticeShown(false);
  }, []);

  const handleAppointmentEditCancel = useCallback(() => {
    onClose();
  }, []);

  const getEmployeeFromStore = useCallback(
    (id: string) => {
      if (!storeState.data?.employees) {
        return null;
      }
      return storeState.data.employees.find((employee) => employee.id === id);
    },
    [storeState.data?.employees],
  );

  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, typeValue, serviceIds, dateValue), shallowEqual);

  const employeesList = useMemo(() => {
    let employeeIsInList = false;
    const addOption = (employee: Employee) => {
      const { id, firstName, lastName } = employee;
      if (employeeValue === id) {
        employeeIsInList = true;
      }
      const nameValues = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);
      return {
        id,
        iconLabel: nameValues.name,
        label: nameValues.fullName,
      };
    };

    const options = availableEmployees.map((employee) => addOption(employee));
    if (employeeValue && !employeeIsInList) {
      const employee = getEmployeeFromStore(employeeValue);
      if (employee) {
        options.push(addOption(employee));
      }
    }

    return sortBy(options, 'label');
  }, [availableEmployees, employeeValue]);

  const slotOptions = useMemo<IOption[]>(
    () =>
      createSlotOptions({
        timeZone,
        employeeId: employeeValue,
        slots: daySlotsState.data,
      }),
    [daySlotsState.data, timeZone, employeeValue],
  );

  useEffect(() => {
    if (daySlotsState.loading) {
      return;
    }

    const isRelatedFieldsDirty = ['date', 'services', 'employee'].some((key) => form.formState.dirtyFields[key]);
    let slotToSet: AppointmentFormSlotItem | null = null;

    const optionByValueMap: Record<number, IOption> = {};
    for (let optionIndex = 0; optionIndex < slotOptions.length; optionIndex += 1) {
      const option = slotOptions[optionIndex];
      optionByValueMap[option.value] = option;
    }

    const possibleValues: AppointmentFormSlotItem[] = [];
    if (slotValue) {
      possibleValues.push(slotValue);
    }

    if (defaultValues.slot && !isRelatedFieldsDirty) {
      possibleValues.push(defaultValues.slot);
    }

    const possibleValue = possibleValues.find((slotOption) => optionByValueMap[slotOption.value]);

    if (possibleValue) {
      slotToSet = possibleValue;
    }

    form.setValue('slot', slotToSet, { shouldValidate: true });
  }, [slotOptions]);

  useEffect(() => {
    if (
      !daySlotsState.loading &&
      employeeValue &&
      typeValue === CalendarEventTypeEnum.APPOINTMENT &&
      (!slotEmployees.find(({ id }) => id === employeeValue) || !slotOptions.length)
    ) {
      form.setError('employee', {
        type: 'employee-no-slots',
        message: intl.formatMessage({ id: 'calendarEvent.employee.noSlotsAvailableError' }),
      });
      return;
    }

    form.clearErrors('employee');
  }, [slotEmployees, employeeValue, daySlotsState.loading, slotOptions]);

  useEffect(() => {
    if (!form.formState.dirtyFields.type) return;

    const formValues = form.getValues();

    form.reset(
      {
        ...formValues,
        type: typeValue,
        title: null,
        ...(typeValue === CalendarEventTypeEnum.APPOINTMENT && {
          from: null,
          to: null,
        }),
        ...(typeValue === CalendarEventTypeEnum.BREAK && {
          title: intl.formatMessage({ id: 'appointment.break' }).toUpperCase(),
        }),
      },
      { keepDirty: true },
    );
  }, [typeValue]);

  const onSubmit = form.handleSubmit(async (formValues: ICalendarEventFormValues) => {
    if (!store) return;
    const createCalendarEventParams = calendarEventFormValuesToCreateCalendarEventParams(formValues);
    const dispatched = await dispatch(createCalendarEvents(createCalendarEventParams));

    if (createCalendarEvents.fulfilled.match(dispatched)) {
      onClose();
      setIsErrorNoticeShown(false);
    }
  });

  useEffect(() => {
    setSlotsErrorShown(Boolean(calendarSlotsState.error || daySlotsState.error));
  }, [calendarSlotsState.error, daySlotsState.error]);

  useEffect(() => {
    if (createCalendarEventState.error && typeValue === CalendarEventTypeEnum.APPOINTMENT) {
      dispatch(getSlotsByDate({ serviceIds, date: dateValue, timestamp: Date.now() }));
    }
  }, [createCalendarEventState.error]);

  useEffect(() => {
    if (!slotValue) return;

    const duration = servicesValue?.reduce((acc, service) => acc + service.extra.duration, 0) || 60;

    form.setValue('from', slotValue.value);
    form.setValue('to', slotValue.value + duration);
  }, [slotValue, typeValue, servicesValue]);

  const prevSlotsPayload = useRef<Nullable<IGetSlotsByDatesPayload>>(null);
  useEffect(() => {
    if (typeValue !== CalendarEventTypeEnum.APPOINTMENT) {
      return;
    }

    const payload = {
      serviceIds: allServices
        .filter((service: Service) => serviceIds.includes(service.id))
        .map((service) => service.id)
        .sort(),
      dates: getDateRange(dateValue, timeZone),
      timestamp: Date.now(),
    };

    let shouldFetch = !prevSlotsPayload.current;

    if (!shouldFetch && prevSlotsPayload.current!.dates[0] !== payload.dates[0]) {
      shouldFetch = true;
    }

    if (!shouldFetch && payload.serviceIds.length !== prevSlotsPayload.current!.serviceIds.length) {
      shouldFetch = true;
    }

    if (!shouldFetch) {
      for (let index = 0; index < payload.serviceIds.length; index += 1) {
        const payloadServiceId = payload.serviceIds[index];
        const prevPayloadServiceId = prevSlotsPayload.current!.serviceIds[index];

        if (payloadServiceId !== prevPayloadServiceId) {
          shouldFetch = true;
          break;
        }
      }
    }

    if (shouldFetch) {
      dispatch(getSlots(payload));

      prevSlotsPayload.current = payload;
    }
  }, [typeValue, dateValue, serviceIds, allServices, timeZone]);

  useEffect(() => {
    if (typeValue !== CalendarEventTypeEnum.APPOINTMENT) {
      return;
    }

    dispatch(
      getSlotsByDate({
        serviceIds: allServices
          .filter((service: Service) => serviceIds.includes(service.id))
          .map((service) => service.id)
          .sort(),
        date: dateValue,
        timestamp: Date.now(),
      }),
    );
  }, [typeValue, dateValue, serviceIds, allServices]);

  useEffect(() => {
    if (dateValue) {
      const from = TimeHelper.toDayjs(dateValue, timeZone);
      dispatch(fetchEmployees({ from }));
    }
  }, [dateValue, timeZone]);

  useEffect(
    () => () => {
      dispatch(resetDaySlots());
      dispatch(resetSlots());
      dispatch(resetCreateCalendarEvent());
    },
    [],
  );

  if (!store) {
    return <></>;
  }

  return (
    <>
      <Dialog
        // eslint-disable-next-line
        open={true}
        fullScreen
      >
        <DialogHeader text={intl.formatMessage({ id: 'calendar.createAppointment' })} onClose={handleAppointmentEditCancel} />
        <DialogContent className={styles.content}>
          <FormProvider {...form}>
            <form>
              <Grid container spacing={2}>
                <Grid item flex={1} xs={8}>
                  <Stack spacing={2}>
                    <Paper>
                      <DialogSection gutterBottom>
                        <Controller
                          name="type"
                          render={({ field }) => (
                            <ToggleButtonGroup
                              exclusive
                              spacing={1}
                              value={field.value}
                              onChange={(event, value) => {
                                if (!value) return;

                                field.onChange(value);
                              }}
                            >
                              {appointmentTypeOptions.map((option) => (
                                <ToggleButton
                                  key={option.value}
                                  value={option.value}
                                  variant="rounded"
                                  color="secondary"
                                  disabled={option.extra?.disabled}
                                >
                                  {intl.formatMessage({ id: option.label })}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                          )}
                        />
                      </DialogSection>

                      {isServiceSectionShown && (
                        <AppointmentFormServiceSection services={allServices} defaultValues={defaultValues.services} />
                      )}
                      {isInformationSectionShown && (
                        <AppointmentFormInformationSection schema={calendarEventFormSchema} />
                      )}
                    </Paper>

                    {(isCustomerSectionShown || isRecurringSectionShown) && (
                      <Paper>
                        <Stack direction="column" spacing={2}>
                          {isCustomerSectionShown && (
                            <DialogSection title={intl.formatMessage({ id: 'appointment.customer' })}>
                              <AppointmentFormCustomerSection size="default" schema={calendarEventFormSchema} />
                            </DialogSection>
                          )}
                          {isRecurringSectionShown && (
                            <AppointmentFormRecurringSection />
                          )}
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                </Grid>

                <Grid item flex={0} xs={4}>
                  <Stack spacing={2}>
                    <Paper>
                      <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                        <Controller
                          control={form.control}
                          name="employee"
                          render={({ field, fieldState }) => (
                            <EmployeeSelectField
                              values={employeesList}
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              error={!!fieldState.error}
                              helperText={
                                !['nullable', 'required'].includes(fieldState.error?.type as string) ? fieldState.error?.message : undefined
                              }
                              required={isEmployeeRequired}
                            />
                          )}
                        />
                      </DialogSection>
                    </Paper>

                    <Paper>
                      <DialogSection title={intl.formatMessage({ id: 'appointment.date' })}>
                        <Spin loading={calendarSlotsState.loading}>
                          <Controller
                            control={form.control}
                            name="date"
                            render={({ field }) => (
                              <CalendarPicker
                                date={dayjs(field.value)}
                                minDate={dayjs(new Date())}
                                renderDay={(_, __, pickersDayProps) => <CustomDay suggestedDays={availableDays} {...pickersDayProps} />}
                                onChange={(value) => {
                                  if (!value) return;

                                  field.onChange(value.format('YYYY-MM-DD'));
                                }}
                              />
                            )}
                          />
                        </Spin>
                      </DialogSection>

                      {isSlotSectionShown && (
                        <Controller
                          control={form.control}
                          name="slot"
                          render={({ field }) => (
                            <PreferredSlotSelectSection
                              value={field.value}
                              onChange={(value) => {
                                field.onChange(value);
                                form.trigger('slot');
                              }}
                              options={slotOptions}
                              loading={daySlotsState.loading}
                            />
                          )}
                        />
                      )}

                      {isTimeSectionShown && (
                        <AppointmentFormTimeSection />
                      )}
                    </Paper>

                    <AsyncButton className={styles.submitButton} variant="contained" color="primary" onClick={onSubmit}>
                      {intl.formatMessage({ id: 'calendar.createAppointment' })}
                    </AsyncButton>

                    {form.formState.isSubmitted && !(form.formState.isValid && isEmpty(form.formState.errors)) && (
                      <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>

      <NoticeStack>
        <Notice
          open={!!isErrorNoticeShown}
          onClose={onErrorNoticeClose}
          severity="error"
          title={intl.formatMessage({ id: 'common.createAppointmentError' })}
          description={intl.formatMessage({ id: 'common.createAppointmentErrorDescription' })}
        />
        <Notice
          open={slotsErrorShown}
          onClose={() => setSlotsErrorShown(false)}
          severity="error"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'slot.slotsFetchErrorTitle' })}
        />
      </NoticeStack>
    </>
  );
}

CreateAppointment.defaultProps = {
  services: undefined,
  time: undefined,
};
