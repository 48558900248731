import React from 'react';
import clsx from 'clsx';
import { ResourceTypeEnum } from '../../../types/ResourceTypeEnum';
import styles from './EventContainerWrapper.module.scss';

export function EventContainerWrapper(props: any) {
  const { children, resource } = props;

  return (
    <div className={
      clsx('events-container', {
        [styles.eventsContainerOverflow]: resource === ResourceTypeEnum.OVERFLOW,
      })
    }
    >
      {children}
    </div>
  );
}
