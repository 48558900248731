import { Appointment, CalendarEventTypeEnum, Service } from '../../types';
import { mapServiceIdsToServices } from '../../utils';

export function GetAppointmentServices(
  appointment: Omit<Appointment, 'duration' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt' | 'id'>,
  allServices: Service[],
): Service[] {
  if (appointment.type && [
    CalendarEventTypeEnum.APPOINTMENT,
    CalendarEventTypeEnum.TASK,
    CalendarEventTypeEnum.WALKIN,
    CalendarEventTypeEnum.RESERVATION,
  ].includes(appointment.type)
  ) {
    return mapServiceIdsToServices(allServices, (appointment.eventTypeData as { services: string[] }).services);
  }
  return [];
}
