import React, { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { Popover } from '@mui/material';
import { useIntl } from 'react-intl';

import styles from './CalendarDay.module.scss';
import { DayOverviewType, OverviewItem } from '../../../types';
import { TimeHelper } from '../../../utils';

type DayComponentProps = {
  date: Dayjs;
  data: DayOverviewType;
  currentDate: Dayjs;
};

export function OverviewItems(props: { items: OverviewItem[], limit?: number }) {
  const intl = useIntl();

  const {
    items,
    limit,
  } = props;

  const elements = limit ? items.slice(0, limit) : items;

  if (!items.length) {
    return <div className={styles.overview_item}>{intl.formatMessage({ id: 'calendarOverview.noEvents' })}</div>;
  }

  return (
    <>
      {
        elements
          .map((item) => (
            <div
              key={`${item.isWalkin}-${item.labels.join('-')}`}
              className={clsx({
                [styles.overview_item]: true,
                [styles.error]: item.color === 'error',
              })}
            >
              <div className={styles.item_value}>
                {item.value}{item.total && `/${item.total}`}
              </div>
              {item.labels.map((label) => intl.formatMessage({ id: label })).join('+')}
              {item.isWalkin ? ` ${intl.formatMessage({ id: 'appointment.walkin' })}` : ''}
              {/* {intl.formatMessage({ id: item.label })} */}
            </div>
          ))

      }
    </>
  );
}

export function CalendarDay({
  date,
  data,
  currentDate,
}: DayComponentProps) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const {
    overview = [],
    weekEnd = false,
  } = data;

  const isToday = useMemo(
    () => TimeHelper.toStandardFormat(date) === TimeHelper.toStandardFormat(currentDate),
    [date, currentDate],
  );

  const isPast = useMemo(() => {
    const today = dayjs.tz();
    return date.isBefore(today, 'day');
  }, [date]);

  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className={clsx(
        {
          [styles.today]: isToday,
          [styles.day_card]: true,
          [styles.active]: !isPast && overview.length && !weekEnd,
        },
      )}
    >
      <div className={styles.title}>{date.format('DD MMM')}</div>
      <div className={styles.title_addon}>
        {isToday && <span className={styles.today_label}>{intl.formatMessage({ id: 'common.today' })}</span>}
      </div>
      {!weekEnd && <OverviewItems items={overview} limit={3} />}

      {overview.length > 0 && (
        <Popover
          id={`mouse-over-popover-${TimeHelper.toStandardFormat(date)}`}
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          slotProps={{
            paper: {
              style: {
                margin: '0 8px',
              },
            },
          }}
        >
          <div className={styles.detailed_overview}>
            <div className={styles.title}>{date.format('DD MMM')}</div>
            <div className={styles.overview_container}>
              <OverviewItems items={overview} />
            </div>
          </div>
        </Popover>
      )}

    </div>
  );
}
